import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReaquestHeadersService } from '../../_services/requestHeaders.service';
import { select, Store } from '@ngrx/store';
import { take, tap, filter, map } from 'rxjs/operators';
import { AppState } from '../../_store/app.reducers';
import * as fromProfile from '../../components/pages/profile/store/profile.reducer';
import { MyQuestsCardListType, MyQuestsFilterStats, QuestView } from '../../_interface/my-quests.types';

@Injectable({
    providedIn: 'root'
})
export class DLMyQuestsService {

  constructor(
    private requestHeadersService: ReaquestHeadersService,
    private store: Store<AppState>,
    private http: HttpClient
  ) {}

  getMyQuests(): Observable<MyQuestsCardListType[]> {
    return new Observable<MyQuestsCardListType[]>(observer => {
      this.store
        .pipe(
          select('userInfo'),
          take(1),
          filter(state => typeof state.id === 'number'),
          map((state: fromProfile.State) => state.id),
          tap((id: number) => {
            this.tryLoadMyQuests(id)
              .pipe(
                map((quests: MyQuestsCardListType[]): MyQuestsCardListType[] => {
                  quests.forEach((quest, i) => {
                    quest.showTools = false;
                    quest.swipeDistanceCount = this.setSwipeDistance(quest.editable);
                    if (quest.isDraft) {
                      quest.filter.active = true;
                      quest.status = "IN_PROGRESS";
                    }
                  });
                  return quests;
                }),
                tap((quests: MyQuestsCardListType[]) => {
                  observer.next(quests);
                  observer.complete();
                })
              )
              .subscribe();
          })
        )
        .subscribe();
    });
  }
  calculateStats(quests: QuestView[]): MyQuestsFilterStats {
    const toNumb = (value) => value ? 1 : 0;
    const res: MyQuestsFilterStats = {
      active: 0,
      completed: 0,
      saved: 0,
    };
    quests.forEach(quest => {
      Object.keys(quest.data.filter).forEach(key => {
        res[key] += toNumb(quest.data.filter[key]);
      });
    });
    return res;
  }
  private tryLoadMyQuests(id: number): Observable<MyQuestsCardListType[]> {
    const headers = this.requestHeadersService.getHeaders();

    return this.http.get<MyQuestsCardListType[]>(
      environment.target + environment.context + `/quests/my/${id}`,
      { headers }
    );
  }
  private setSwipeDistance(editable: boolean): number {
    let swipeDistanceCount = 0;
    if (editable) {
      swipeDistanceCount += 1;
    }
    return swipeDistanceCount;
  }
}
