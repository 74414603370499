import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotifierModule } from 'angular-notifier';
// import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { AutosizeModule } from 'ngx-autosize';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ClipboardModule } from 'ngx-clipboard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxTributeModule } from 'ngx-tribute';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthEffects } from './components/main/auth/store/auth.effects';
import { ContactUsComponent } from './components/main/contact-us/contact-us.component';
import { AdvanceDetailsComponent } from './components/main/dl-auth/brand-signup/advance-details/advance-details.component';
import { BasicDetailsComponent } from './components/main/dl-auth/brand-signup/basic-details/basic-details.component';
import { DlAuthComponent } from './components/main/dl-auth/dl-auth.component';
import { DlLogInComponent } from './components/main/dl-auth/dl-log-in/dl-log-in.component';
import { UserSignupComponent } from './components/main/dl-auth/user-signup/user-signup.component';
import { DlMilestonesEffects } from './components/main/dl-milestones/store/dl-milestones.effects';
import { HeaderComponent } from './components/main/header/header.component';
// tslint:disable-next-line:max-line-length
import { MembersDirective } from './components/main/members/members.directive';
import { NotificationsComponent } from './components/main/notifications/notifications.component';
// tslint:disable-next-line:max-line-length
import { SkipNavComponent } from './components/main/skip-nav/skip-nav.component';
// tslint:disable-next-line:max-line-length
import { FriendsComponent } from './components/pages/friends/friends.component';
import { ProfileEffects } from './components/pages/profile/store/profile.effects';
import { RecentTransactionsEffects } from './components/pages/profile/store/recent-transactions.effects';
import { UserFavoritesEffects } from './components/pages/profile/store/user-favorites.effects';
import { QuestTeamModalEffects } from './components/pages/quest-detail/store/quest-team-modal.effects';
import { InputFocusTriggerDirective } from './directives/focus-trigger/input-focus-trigger.directive';
import { RouterLinkDirective } from './directives/routerLink/router-link.directive';
import { ToggleDirective } from './directives/toggle/toggle.directive';
import { HTTPListener, HTTPStatus } from './interceptors/loader.interceptor';
// modules
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GrdFilterPipe } from './_pipes/grd-filter.pipe';
import { HtmlToPlaintextPipe } from './_pipes/html-to-plain-text.pipe';
import { OptiImagePipe } from './_pipes/opti-image.pipe';
import { LogActivityComponent } from './components/separate/log-activity/log-activity.component';
import { SharedModule } from './modules/shared-module/shared.module';

// import { SafePipe } from './_pipes/safe-pipe';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { QuestDetailEffects } from './_interface/store/quest-detail.effects';
import { UdpCurrencyMaskV2Pipe } from './_pipes/udp-currency-mask-v2.pipe';
import { UdpCurrencyMaskPipe } from './_pipes/udp-currency-mask.pipe';
import { CommonService } from './_services/common.service';
import { metaReducers, reducers } from './_store/app.reducers';
import { SmallLogInComponent } from './components/main/dl-auth/small-log-in/small-log-in.component';
import { FooterComponent } from './components/main/footer/footer.component';
import { NotificationListComponent } from './components/main/notifications/component/notification-list/notification-list.component';
import { NotificationsEffects } from './components/main/notifications/store/notifications.effects';
import { AccountEffects } from './components/pages/account/store/account.effects';

@NgModule({
  declarations: [
    AppComponent,
    UdpCurrencyMaskPipe,
    UdpCurrencyMaskV2Pipe,
    HtmlToPlaintextPipe,
    GrdFilterPipe,
    HeaderComponent,
    FooterComponent,
    FriendsComponent,
    NotificationListComponent,
    NotificationsComponent,
    ToggleDirective,
    InputFocusTriggerDirective,
    MembersDirective,
    SkipNavComponent,
    RouterLinkDirective,
    DlAuthComponent,
    DlLogInComponent,
    OptiImagePipe,
    ContactUsComponent,
    UserSignupComponent,
    BasicDetailsComponent,
    AdvanceDetailsComponent,
    LogActivityComponent,
    SmallLogInComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    StoreModule.forRoot(reducers, {metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    NgbModule,
    EffectsModule.forRoot([
      AuthEffects,
      AccountEffects,
      ProfileEffects,
      RecentTransactionsEffects,
      UserFavoritesEffects,
      QuestTeamModalEffects,
      DlMilestonesEffects,
      QuestDetailEffects,
      NotificationsEffects
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    SharedModule,
    AppRoutingModule,
    NgSelectModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'bottom',
        },
      },
      theme: 'material',
      behaviour: {
        autoHide: 3000,
        onClick: false,
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4,
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease',
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50,
        },
        shift: {
          speed: 300,
          easing: 'ease',
        },
        overlap: 150,
      }
    }),
    QuillModule.forRoot(),
    // RecaptchaModule,
    // RecaptchaFormsModule,
    NgxCaptchaModule,
    AutosizeModule,
    ClipboardModule,
    NgxTributeModule,
    InfiniteScrollModule,  
    SocialLoginModule,
    NgScrollbarModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbAppId)
          }
        ]
      } as SocialAuthServiceConfig
    },
    HTTPStatus,
    HTTPListener,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    CommonService,
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {siteKey: config.reCaptchaToken} as RecaptchaSettings,
    // },
    UdpCurrencyMaskPipe,
    UdpCurrencyMaskV2Pipe,
    NgbActiveModal,
    {
      provide: 'isServer',
      useValue: false
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
