import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { mergeMap, switchMap } from "rxjs";
import { NotificationType } from "src/app/_interface/notification.types";
import * as fromApp from '../../../../_store/app.reducers';
import { NotificationsService } from "../notifications.service";
import { ALL_NOTIFICATIONS_LOADED, GET_NOTIFICATIONS, IS_NOTIFICATIONS_LOADING, IsNotificationsLoading, SET_NOTIFICATIONS, SET_NOTIFICATIONS_INFO } from "./notifications.actions";

@Injectable()
export class NotificationsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private notificationsService: NotificationsService
  ) {}

  getNotifications = createEffect(() => this.actions$
    .pipe(
      ofType(GET_NOTIFICATIONS),
      switchMap((notifPayload: { userId: number, startCount: number, sizeNum: number }) => {
        this.store.dispatch(new IsNotificationsLoading(true));

        return this.notificationsService.getNotifications(notifPayload.userId, notifPayload.startCount, notifPayload.sizeNum).pipe(
          mergeMap((res: NotificationType[]) => {
            let unreadCount = 0;
            res.forEach(notif => {
              !notif.isRead ? unreadCount += 1 : null;
            });

            let allLoaded = false;
            if (!res.length || res.length < notifPayload.sizeNum ) allLoaded = true;

            return [{
                type: SET_NOTIFICATIONS,
                payload: res
              },
              {
                type: SET_NOTIFICATIONS_INFO,
                payload: unreadCount
              },
              {
                type: IS_NOTIFICATIONS_LOADING,
                payload: false
              },
              {
                type: ALL_NOTIFICATIONS_LOADED,
                payload: allLoaded
              }
            ];
          })
        )
      })
    )
  )
}
