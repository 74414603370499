import {
  trigger,
  state,
  animate,
  transition,
  style, keyframes, query, stagger, animateChild
} from '@angular/animations';

export const AddCommentToList = trigger('AddCommentToList', [
  state('in', style({
    opacity: 1,
    transform: 'none'
  })),
  state('void', style({
    opacity: 0,
    transform: 'transLateX(-100px)'
  })),
  transition('void => *', [
    animate('200ms ease-in')
  ])
]);

export const AnimateList = trigger('animateList', [
  transition(':enter', [
    // child animation selector + stagger
    query('@animateItems',
      stagger(-100, animateChild()),
      { optional: true }
    )
  ])
]);
export const AnimateToggleList = trigger('animateToggleList', [
  transition(':enter', [
    // child animation selector + stagger
    query('@animateItems',
      stagger(-100, animateChild()),
      { optional: true }
    )
  ]),
  transition(':leave', [
    // child animation selector + stagger
    query('@animateItems',
      stagger(-100, animateChild()),
      { optional: true }
    )
  ])
]);
export const AnimateItems = trigger('animateItems', [
  // cubic-bezier for a tiny bouncing feel
  transition('void => *', [
    style({ transform: 'transLateX(-1rem)', opacity: 0 }),
    animate('300ms cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'none', opacity: 1 }))
  ]),
  transition('* => void', [
    style({ opacity: 1, height: '*' }),
    animate('300ms cubic-bezier(.8,-0.6,0.2,1.5)', style({ opacity: 0, height: '0px', padding: '0px' }))
  ])
]);
export const AnimateItemsUp = trigger('animateItemsUp', [
  // cubic-bezier for a tiny bouncing feel
  transition('void => *', [
    style({ opacity: 0 }),
    animate('300ms cubic-bezier(.8,-0.6,0.2,1.5)', style({ opacity: 1 }))
  ]),
  transition('* => void', [
    style({ opacity: 1, height: '*' }),
    animate('300ms cubic-bezier(.8,-0.6,0.2,1.5)', style({ opacity: 0, height: '0px', padding: '0px' }))
  ])
]);

export const FadeIn = trigger('fadeAnimation', [
  state('in', style({opacity: 1})),
  transition(':enter', [
    style({opacity: 0}),
    animate(250 )
  ]),
  transition(':leave',
    animate(250, style({opacity: 0})))
]);
