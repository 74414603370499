import {Injectable} from '@angular/core';

import * as fromApp from './../_store/app.reducers';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  constructor() {
  }

  set(key: string, state: fromApp.anyState) {
    localStorage.setItem(key, JSON.stringify(state));
    return state;
  }

  get(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  clear(key: string) {
    localStorage.removeItem(key);
    return true;
  }

  /**
   * 
   * @param key 
   * @param initialState 
   * @param isProfileInfo -> created to remove the friends param from the key
   * @returns 
   */
  getInitialState (key: string, initialState: fromApp.anyState, isProfileInfo = false) {
    let state = this.get(key);
    if (isProfileInfo) {
      state = {
        ...state,
        hasGotCurrentFriends: false,
        currentFriends: [],
        hasGotFriendRequests: false,
        friendRequests: [],
        hasGotFriendsNoAction: false,
        friendsNoAction: []
      };
    }

    if (state) {
      return state;
    } else {
      return initialState;
    }
  }

}
