import { Directive, OnChanges, Input, Renderer2, ElementRef } from '@angular/core';


@Directive({
  selector: '[routerLink]'
})
export class RouterLinkDirective implements OnChanges {
  @Input() routerLink: any;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    if (this.routerLink == null) {
      setTimeout(() => this.renderer.removeAttribute(this.elRef.nativeElement, 'href'), 0);
    }
  }
}
