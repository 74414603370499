import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../../_store/app.reducers';
import * as fromProfile from '../../../../components/pages/profile/store/profile.reducer';

@Component({
  selector: 'app-tier-badge',
  templateUrl: './tier-badge.component.html',
  styleUrls: ['./tier-badge.component.scss']
})
export class TierBadgeComponent implements OnInit, OnChanges {
  userInfoState: Observable<fromProfile.State>;

  @Input('badgeText') badgeText: string;

  constructor(private store: Store<AppState>) { 
    this.userInfoState = this.store.select('userInfo');
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.badgeText || this.badgeText === '') {
      this.badgeText = 'Pro';
    } else {
      this.badgeText = this.badgeText.toLowerCase();
    }
  }

}
