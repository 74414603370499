import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  @Input('videoUrl') videoUrl: string = '';

  @Input('imageUrl') imageUrl: string = '';
  
  @Input('startPlay') startPlay: boolean = false;

  @ViewChild('imageParentElem', {read: ElementRef, static: false}) imageParentElem: ElementRef;
  
  @ViewChild('videoParentElem', {read: ElementRef, static: false}) videoParentElem: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    setTimeout(() => {
      if (this.startPlay) {
        this.playVideo();
      }
    }, 100);
  }

  playVideo() {
    this.renderer.addClass(this.imageParentElem.nativeElement, 'hidden');
    this.renderer.removeClass(this.videoParentElem.nativeElement, 'hidden');

    let _video = this.videoParentElem.nativeElement.querySelectorAll('video');
    _video[0].play();
  }

}
