import { BrandConfig } from '../brand.types';
import { LoggedActivity, Quest, QuestGalleryImage, QuestMembersApi } from '../quest.types';
import { QuestTeam, QuestTeamFundraise } from '../team.types';
import {
  BRANDS_LOADED,
  CLEAR_TEAM_MODAL_CONFIG,
  IS_CURRENT_USER_ADMIN_OR_OWNER,
  LOGGED_IN_USER_VERSION_EXISTS,
  OPEN_CREATE_TEAM_MODAL,
  OPEN_LOG_ACTIVITY_MODAL,
  PERFORM_ACTION_AFTER_LOAD,
  QUEST_LOGGED_ACTIVITIES,
  QUEST_NEW_LOGGED_ACTIVITY,
  QuestActions,
  SET_PARENT_QUEST_TEAMS,
  SET_QUEST_DETAIL,
  SET_QUEST_GALLERY_ASSETS,
  SET_QUEST_ID,
  SET_QUEST_LOGGED_USER_TEAM_ID,
  SET_QUEST_USER_ID,
  TEAMS_LOADED,
  TEAM_CREATED,
  TEAM_JOINED,
  TEAM_MODAL_CONFIG,
  UPDATE_IS_DOER_FUNDRAISING_LINK,
  UPDATE_QUEST_LOGGED_ACTIVITY,
  UPDATE_QUEST_MEMBERS_AND_COUNT,
  UPDATE_TEAMS_WITHOUT_FUNDRAISING
} from './quest-detail.actions';

export interface QuestDetailState {
  selectedQuestId: number;
  selectedQuestUserId: number;
  selectedQuestTeams: QuestTeam[];
  selectedQuestBrands: BrandConfig[];
  joinedTeam: QuestTeam;
  questActivitiesLogged: LoggedActivity[];
  selectedQuestGallery: QuestGalleryImage[];
  selectedQuestDetail: Quest;
  selectedQuestMembersAndCount: QuestMembersApi;

  openLogActivityModal: boolean;
  logActivityPreSelectedId: number;
  teamsWithoutFundraising: boolean;
  hasSetTeamsWithoutFundraising: boolean;
  isDoerHasFundraisingLink: boolean;
  hasSetIsDoerHasFundraisingLink: boolean;
  actionAfterLoad: string;
  userVersionExists: boolean;
  userNotAllowedToView: boolean;
  openCreateTeamModal: boolean;
  createTeamModalConfig: QuestTeamFundraise;
  loggedUserTeamId: number;
  parentQuestTeams: Quest[]; //the quest list for MQ
  isCurrentUserAdminOrOwner: boolean;
}

const initialState: QuestDetailState = {
  selectedQuestId: null,
  selectedQuestUserId: null,
  selectedQuestTeams: [],
  selectedQuestBrands: [],
  joinedTeam: null,
  questActivitiesLogged: [],
  selectedQuestGallery: [],
  selectedQuestDetail: null,
  selectedQuestMembersAndCount: null,
  openLogActivityModal: false,
  logActivityPreSelectedId: null,
  teamsWithoutFundraising: false,
  hasSetTeamsWithoutFundraising: false,
  isDoerHasFundraisingLink: false,
  hasSetIsDoerHasFundraisingLink: false,
  actionAfterLoad: null,
  userVersionExists: false,
  userNotAllowedToView: false,
  openCreateTeamModal: false,
  createTeamModalConfig: null,
  loggedUserTeamId: null,
  parentQuestTeams: [],
  isCurrentUserAdminOrOwner: false
};

export function QuestReducer(
  state = initialState,
  action: QuestActions
) {
  switch (action.type) {
    case SET_QUEST_ID:
      return {
        ...state,
        selectedQuestId: action.payload
      };
    case SET_QUEST_USER_ID:
      return {
        ...state,
        selectedQuestUserId: action.payload
      };
    case BRANDS_LOADED:
      return {
        ...state,
        selectedQuestBrands: action.brands
      };
    case TEAM_CREATED:
      return {
        ...state,
        // joinedTeam: action.team,
        selectedQuestTeams: [...state.selectedQuestTeams, action.team]
      };
    case TEAM_JOINED:
      return {
        ...state,
        joinedTeam: action.team
      };
    case TEAMS_LOADED:
      return {
        ...state,
        selectedQuestTeams: action.teams
      };
    case QUEST_LOGGED_ACTIVITIES:
      return {
        ...state,
        questActivitiesLogged: action.payload
      }
    case QUEST_NEW_LOGGED_ACTIVITY:
      let allActivities = state.questActivitiesLogged;
      allActivities.unshift(action.payload);
      return {
        ...state,
        questActivitiesLogged: allActivities
      }
    case UPDATE_QUEST_LOGGED_ACTIVITY:
      let oldActivities = state.questActivitiesLogged;
      oldActivities.forEach((activity, i) => {
        if (activity.actvityRecordValueId === action.payload.actvityRecordValueId) {
          oldActivities[i] = action.payload;
        }
      });

      return {
        ...state,
        questActivitiesLogged: oldActivities
      }
    case SET_QUEST_GALLERY_ASSETS:
      return {
        ...state,
        selectedQuestGallery: action.payload
      }
    case SET_QUEST_DETAIL:
      return {
        ...state,
        selectedQuestDetail: action.payload
      }
    case OPEN_LOG_ACTIVITY_MODAL:
      return {
        ...state,
        openLogActivityModal: action.isOpen,
        logActivityPreSelectedId: action.taskId || null
      }
    case UPDATE_IS_DOER_FUNDRAISING_LINK:
      return {
        ...state,
        isDoerHasFundraisingLink: action.payload,
        hasSetIsDoerHasFundraisingLink: action.payload === null ? false : true
      };
    case UPDATE_TEAMS_WITHOUT_FUNDRAISING:
      return {
        ...state,
        teamsWithoutFundraising: action.payload,
        hasSetTeamsWithoutFundraising: action.payload === null ? false : true
      };
    case PERFORM_ACTION_AFTER_LOAD: 
      return {
        ...state,
        actionAfterLoad: action.payload
      };
    case LOGGED_IN_USER_VERSION_EXISTS:
      return {
        ...state,
        userVersionExists: action.payload
      }
    case OPEN_CREATE_TEAM_MODAL:
      return {
        ...state,
        openCreateTeamModal: action.payload
      }
    case TEAM_MODAL_CONFIG:
      return {
        ...state,
        createTeamModalConfig: action.payload
      };
    case CLEAR_TEAM_MODAL_CONFIG:
      return {
        ...state,
        createTeamModalConfig: null
      };
    case UPDATE_QUEST_MEMBERS_AND_COUNT:
      return {
        ...state,
        selectedQuestMembersAndCount: action.payload
      }
    case SET_QUEST_LOGGED_USER_TEAM_ID:
      return {
        ...state,
        loggedUserTeamId: action.payload
      }
    case SET_PARENT_QUEST_TEAMS:
      return {
        ...state,
        parentQuestTeams: action.payload
      };
    case IS_CURRENT_USER_ADMIN_OR_OWNER:
      return {
        ...state,
        isCurrentUserAdminOrOwner: action.payload
      };
    
    default:
      return state;
  }
}

export const questTeamsChanged = (state: QuestDetailState) => state.selectedQuestTeams;
