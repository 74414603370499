import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {HTTPStatus} from 'src/app/interceptors/loader.interceptor';
import {Observable} from 'rxjs';
import { NotificationType } from 'src/app/_interface/notification.types';
import { NotificationLogic } from 'src/app/_interface/notification.logic';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  constructor(
    private http: HttpClient,
    private httpStatus: HTTPStatus,
    private reaquestHeadersService: ReaquestHeadersService,
  ) {
  }

  getUserInfo(userId) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.get(
      environment.target + environment.context + `/getuserinfo`,
      {headers: headers}
    );
  }

  getNotifications(userId, startPosition, endPosition): Observable<NotificationType[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.get<NotificationType[]>(
      environment.target + environment.context + `/notifications/${userId}/${startPosition}/${endPosition}`,
      {headers: headers}
    );
  }

  readNotification(notification: NotificationType) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    const payload = NotificationLogic.buildPayloadForReadNotification(notification);
    return this.http.post<void>(
      environment.target + environment.context + `/notifications`,
      payload,
      {headers: headers}
    );
  }

  hasNotifications(userId): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<number>(
      environment.target + environment.context + `/notifications/check/${userId}`,
      {headers: headers}
    );
  }

  markAllAsRead(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<number>(
      environment.target + environment.context + `/notifications/markread/${userId}`,
      {headers: headers}
    );
  }
}
