import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobileSideNavService {
  renderer: Renderer2;

  openFor: string;

  openedForElem: ElementRef;
  
  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  openSideNav(elementRef: ElementRef, _openFor: string): void {
    if (elementRef) {
      this.renderer.addClass(elementRef.nativeElement, 'active-side-menu');
      this.openedForElem = elementRef;
    }

    this.openFor = _openFor;
  }

  closeSideNav(elementRef?: ElementRef): void {
    this.openFor = '';

    if (this.openedForElem) this.renderer.removeClass(this.openedForElem.nativeElement, 'active-side-menu');
    if (elementRef) this.renderer.removeClass(elementRef.nativeElement, 'active-side-menu');
  }
}
