import { HttpClient } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReaquestHeadersService } from './requestHeaders.service';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // reader = new FileReader();
  // readerListener: () => void;
  // renderer: Renderer2;

  private showNotificationSubj = new BehaviorSubject<{notifType: string, notifMsg: string}>(null);

  showNotification$ = this.showNotificationSubj.asObservable();

  constructor(
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    private reaquestHeadersService: ReaquestHeadersService,
    private titleService: Title,
    private meta: Meta) {
      // this.renderer = rendererFactory.createRenderer(null, null);
  }

  uploadImageAndGetUrl(formData: FormData): Observable<any> {
    return this.http.post(
      environment.target + environment.authContext + '/image/upload',
      formData,
      {
        reportProgress: true, observe: "events"
      }
    );
  }

  uploadMultipleImagesAndGetUrl(formData: FormData): Observable<any> {
    return this.http.post(
      environment.target + environment.authContext + '/images/upload',
      formData,
      {
        reportProgress: true, observe: "events"
      }
    );
  }

  readImageAndReturnBase64(file: File, callback: Function) {
    let _reader = new FileReader();
    _reader.onload = ((event) => {
      // console.log('49', event);
      callback(event.target.result);
    });

    // this.readerListener = this.renderer.listen(this.reader, "load", (event) => {
    //   this.unlistenRenderer();
    //   console.log('52', event);

    //   callback(event.target.result);
    // });
    _reader.readAsDataURL(file);

    // this.reader.readAsDataURL(file);
  }

  // unlistenRenderer(): void {
  //   this.readerListener();
  // }

  removeAssetFromS3(assetUrl: string): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.authContext + '/delete/image',
      assetUrl,
      {
        headers: headers
      }
    )
  }
  
  /**
   * @description: to show the notifications(using notifier service module) with the icon. 
   * html placed in the app.component.ts
   * @param type - type of notification such as error, warning, success, info
   * @param message 
   */
  showNotification(type:string, message: string) {
    this.showNotificationSubj.next({
      notifType: type,
      notifMsg: message
    });
  }

  getNotification(): Observable<any> {
    return this.showNotification$;
  }

  /**
   * getRandomInt
   * @description: return a random number between the min and max passed
   * @param min: muinimum number
   * @param max: maximum number
   */
  getRandomInt(min, max) {
    let _min = min;
    let _max = max;
    _min = Math.ceil(_min);
    _max = Math.floor(_max);

    return Math.floor(Math.random() * (_max - _min + 1)) + _min;
  }

  /**
   * @method: setMetaTagsToDIEMlife
   * @description: Set meta tags to default of DIEMlife
   */
  setMetaTagsToDIEMlife() {
    this.titleService.setTitle('DIEMlife');
    this.meta.updateTag({property: 'og:title', content: 'DIEMlife'});
    this.meta.updateTag({property: 'og:image', content: '/assets/images/useful/logo_diemlife_text.webp'});
    this.meta.updateTag({property: 'og:description', content: 'Community enagement, amplified.  We enable organizations to effectively manage the wellness of their communities.'});
  }

  convertHeicToJpeg(s3Path: string): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + '/api/v5/convert-image', //'https://ec2-174-129-106-55.compute-1.amazonaws.com:5000/convert-image',
      {heic_file_path: s3Path},
      {
        headers: headers
      }
    )      
  }
}
