import { Pipe, PipeTransform } from '@angular/core';
import { MemberActivity } from 'src/app/_interface/quest.types';
import { SortMembersUtils } from 'src/app/_interface/utils/sort-members.utils';

@Pipe({
  name: 'sortMembers'
})
export class SortMembersPipe implements PipeTransform {

  transform(value: MemberActivity[], options: { isListSort?: any, mode: string }): MemberActivity[] {
    if (options.mode !== 'doers') {
      return value;
    }

    return SortMembersUtils.sortDoers(value, options.isListSort);
  }

}
