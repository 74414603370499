import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import * as ColorContrastChecker from 'color-contrast-checker';
import { forkJoin, Observable } from 'rxjs';
import { QuestInfo, QuestUsageDetailInfo, QuestUserInfo } from 'src/app/_interface/quest.types';
import { environment } from '../../../../environments/environment';
import { ReaquestHeadersService } from '../../../_services/requestHeaders.service';
import { BankAccount, CreditCard } from './payments/store/payments.reducer';
import { AccountColor } from './store/account.reducer';
import * as fromApp from '../../../_store/app.reducers';
import { take, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PlanMemberUsage } from './account.types';
import { HTTPStatus } from 'src/app/interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private notifier: NotifierService,
    private store: Store<fromApp.AppState>,
    @Inject(DOCUMENT) private _doc: Document,
    @Inject('isServer') private isServer: boolean,
    private httpStatus: HTTPStatus
  ) { }
  getSupportedCountriesStripe () {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/supported-countries',
      {
        headers: headers
      }
    );
  }
  getUserInfo (): Observable<QuestUserInfo> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestUserInfo>(
      environment.target + environment.context + '/getuserinfo',
      {
        headers: headers,
        withCredentials: true
      }
    );
  }
  getUserInfoById (id) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      userId: id
    };
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/getuserinfobyid',
      payload,
      { headers: headers }
    );
  }
  getBankAccount (purpose: string): Observable<BankAccount[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<BankAccount[]>(
      environment.target + environment.context + `/accounts/bank-accounts/list/${purpose}`,
      { headers: headers }
    );
  }

  getBankAccounts(): Observable<BankAccount[][]> {
    return forkJoin([this.getBankAccount('payments'), this.getBankAccount('payouts')]);
  }

  getCreditCards(): Observable<CreditCard[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<CreditCard[]>(
      environment.target + environment.context + '/accounts/listcreditcards',
      { headers: headers }
    );
  }
  getFullyPaymentsInfo(): Observable<[BankAccount[][], CreditCard[]]> {
    return forkJoin([this.getBankAccounts(), this.getCreditCards()]);
  }
  getAccountInfo () {
    return forkJoin([this.getUserInfo(), this.getSupportedCountriesStripe()]);
  }
  getFullyAccountInfo () {
    return forkJoin([this.getUserInfo(), this.getCreditCards()]);
  }
  editProfile (payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/editprofile',
      payload,
      { headers: headers }
    );
  }
  editPassword (password: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      password: password
    };
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/changepassword',
      payload,
      { headers: headers }
    );
  }

  saveCustomColor(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/user/addcustomcolor',
      payload,
      { headers: headers }
    );
  }

  getCustomColor(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/user/getcustomcolor/${userId}`,
      { headers: headers }
    );
  }

  // setColorInStorage(colorObj: AccountColor) {
  //   localStorage.setItem('custom-color', JSON.stringify(colorObj));
  // }

  // getColorFromStorage(): AccountColor {
  //   return JSON.parse(localStorage.getItem('custom-color'));
  // }

  checkAccountColorAndApply(userId?: number) {
    this.store.select('account')
      .subscribe(res => {
        if (res.accentColor && res.accentColor !== '' && res.themeColor && res.themeColor !== '') {
          this.changeRootColor(res, userId);
        } else {
          this.resetAllRootColors();
        }
      });
  }

  resetAllRootColors() {
    if (this.isServer) {
      return;
    }
    
    document.documentElement.style.removeProperty(`--accent-color`);
    document.documentElement.style.removeProperty(`--primary-bg`);
    document.documentElement.style.removeProperty(`--white-section-bg`);
    document.documentElement.style.removeProperty(`--bg-text-color`);
    document.documentElement.style.removeProperty(`--theme-text-light`);
    document.documentElement.style.removeProperty(`--theme-text-dark`);
    document.documentElement.style.removeProperty(`--btn-text-light`);
    document.documentElement.style.removeProperty(`--accent-bg-light-color`);
    document.documentElement.style.removeProperty(`--btn-text-color`);
    document.documentElement.style.removeProperty(`--quest-content-bg`);
    document.documentElement.style.removeProperty(`--quest-highlight-bg`);
    document.documentElement.style.removeProperty(`--modal-bg`);
    document.documentElement.style.removeProperty(`--feedback-bg`);
    document.documentElement.style.removeProperty(`--bg-text-color-light`);
    document.documentElement.style.removeProperty(`--transparent-white-bg`);
    document.documentElement.style.removeProperty(`--bg-white-ghost`);
    document.documentElement.style.removeProperty(`--comment-blue-bg`);
    document.documentElement.style.removeProperty(`--bg-milestone`);
    document.documentElement.style.removeProperty(`--white-bg-original`);
    document.documentElement.style.removeProperty(`--accent-dark-color`);
    document.documentElement.style.removeProperty(`--milestone-task-bg`);
    document.documentElement.style.removeProperty(`--flow-pages-bg`);
    document.documentElement.style.removeProperty(`--tooltip-bg`);
    document.documentElement.style.removeProperty(`--tooltip-text-color`);
    document.documentElement.style.removeProperty(`--flow-pages-section-bg`);
    document.documentElement.style.removeProperty(`--nav-flow-number-bg`);
    document.documentElement.style.removeProperty(`--nav-flow-number-color`);
    document.documentElement.style.removeProperty(`--close-btn-invert`);
    document.documentElement.style.removeProperty(`--payment-block-row`);
    document.documentElement.style.removeProperty(`--search-hover-bg`);
    document.documentElement.style.removeProperty(`--progress-bar-uncovered`);
    document.documentElement.style.removeProperty(`--read-more-btn-text`);
    document.documentElement.style.removeProperty(`--read-more-gradient`);
    document.documentElement.style.removeProperty(`--dots-bg`);
    document.documentElement.style.removeProperty(`--dots-color`);
    document.documentElement.style.removeProperty(`--total-amount-color`);
    document.documentElement.style.removeProperty(`--team-option-bg`);
    document.documentElement.style.removeProperty(`--team-option-bg-dark`);
    document.documentElement.style.removeProperty(`--checkbox-selection-color`);
    document.documentElement.style.removeProperty(`--comment-text-color`);
    document.documentElement.style.removeProperty(`--primary-bg-secondary`);
    document.documentElement.style.removeProperty(`--quest-detail-right`);
    document.documentElement.style.removeProperty(`--bg-text-color-secondary`);
    document.documentElement.style.removeProperty(`--table-member-bg`);
    document.documentElement.style.removeProperty(`--table-rank-bg`);
    document.documentElement.style.removeProperty(`--table-gutter-bg`);
    document.documentElement.style.removeProperty(`--quest-header-bg`);
    document.documentElement.style.removeProperty(`--lighter-btn-bg`);
    document.documentElement.style.removeProperty(`--timeline-bg`);

    document.documentElement.style.removeProperty(`--sample-btn-text-color`);
    document.documentElement.style.removeProperty(`--sample-btn-text-light`);
    document.documentElement.style.removeProperty(`--sample-theme-text-light`);
    document.documentElement.style.removeProperty(`--sample-theme-bg-color`);
    document.documentElement.style.removeProperty(`--sample-theme-bg-color-light`);
    document.documentElement.style.removeProperty(`--sample-bg-text-color`);
    document.documentElement.style.removeProperty(`--sample-quest-content-bg`);
    document.documentElement.style.removeProperty(`--sample-primary-bg`);
    document.documentElement.style.removeProperty(`--marked-milestone-bg`);
    document.documentElement.style.removeProperty(`--marked-milestone-bg-hover`);
    document.documentElement.style.removeProperty(`--accent-color-secondary-light`);
    document.documentElement.style.removeProperty(`--quest-detail-nav`);
    document.documentElement.style.removeProperty(`--quest-detail-nav-dark`);
    document.documentElement.style.removeProperty(`--avatar-border`);

    document.documentElement.style.removeProperty(`--site-header`);
    document.documentElement.style.removeProperty(`--site-header-text`);
    document.documentElement.style.removeProperty(`--site-header-highlight`);
    document.documentElement.style.removeProperty(`--site-header-highlight-light`);
    document.documentElement.style.removeProperty(`--site-header-highlight-text`);
    document.documentElement.style.removeProperty(`--site-header-highlight-text-invert`);
    document.documentElement.style.removeProperty(`--site-header-box-shadow`);
    document.documentElement.style.removeProperty(`--site-header-badge-bg`);
    document.documentElement.style.removeProperty(`--site-header-badge-text`);

    document.documentElement.style.removeProperty(`--success-bg-btn`);
    document.documentElement.style.removeProperty(`--success-bg-text`);
    
    document.documentElement.style.removeProperty(`--close-btn-invert-str`);
    
    document.documentElement.style.setProperty(`--bs-backdrop-bg-custom`, '#000000');
  }

  changeRootColor(colorObject: AccountColor, userId?: number) {
    if (this.isServer) {
      return;
    }
    this.resetAllRootColors();

    const themeColor = colorObject['themeColor']; //bg color
    const accentColor = colorObject['accentColor']; //changing the teal

    // console.log('238 change root color', themeColor, accentColor);
    if (themeColor === '#E5EAED' && accentColor === '#008297') {
      this.resetAllRootColors();

      return;
    }
    
    // const navColor = '#0B0135';
    // let usersToCheckArr = [1607, 2415]; //todo: make check for development and production
    // if (environment.production) {
      // usersToCheckArr = [];
    // }
    // console.log('224', userId, colorObject)
    // checking color contrast
    const ccc = new ColorContrastChecker();

    // console.log('1101-', ccc.isLevelAA(themeColor, accentColor, 16), ccc.isLevelAA(accentColor, themeColor, 16));

    this._doc.documentElement.style.setProperty(`--accent-color`, accentColor);
    this._doc.documentElement.style.setProperty(`--total-amount-color`, accentColor);
    this._doc.documentElement.style.setProperty(`--marked-milestone-bg`, accentColor);
    this._doc.documentElement.style.setProperty(`--marked-milestone-bg-hover`, accentColor);
    
    // userId && usersToCheckArr.findIndex(u => u === userId) > -1 ? null : this._doc.documentElement.style.setProperty(`--quest-detail-nav`, accentColor);
    this._doc.documentElement.style.setProperty(`--quest-detail-nav`, accentColor);
    this._doc.documentElement.style.setProperty(`--quest-header-bg`, accentColor);
    
    this._doc.documentElement.style.setProperty(`--site-header`, accentColor);
    this._doc.documentElement.style.setProperty(`--success-bg-btn`, accentColor);

    // only apply if themeColor is not default one
    if (themeColor !== '#E5EAED') {
      // userId && usersToCheckArr.findIndex(u => u === userId) > -1 ? null : this._doc.documentElement.style.setProperty(`--quest-header-bg`, themeColor);
      this._doc.documentElement.style.setProperty(`--primary-bg`, themeColor);
      this._doc.documentElement.style.setProperty('--white-section-bg', `${themeColor}`);
      this._doc.documentElement.style.setProperty('--progress-bar-uncovered', `${themeColor}`);
      this._doc.documentElement.style.setProperty(`--modal-bg`, themeColor);
      this._doc.documentElement.style.setProperty(`--flow-pages-section-bg`, themeColor);
      this._doc.documentElement.style.setProperty(`--read-more-gradient`, themeColor);
    }
    
    const textColorBlack = '#2e3638';
    const textColorWhite = '#ffffff';

    // checking below for theme's color ie btn and nav bar of quest
    // 16 is average font size used
    if (ccc.isLevelAA(accentColor, textColorBlack, 18)) {
      // black is valid
      console.log('changeRootColor 157 black is valid');

      const lighterColor = this.LightenDarkenColor(accentColor.replace('#', ''), 30);
      this._doc.documentElement.style.setProperty(`--theme-text-light`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--theme-text-dark`, textColorWhite);
      this._doc.documentElement.style.setProperty('--btn-text-light', textColorBlack);
      this._doc.documentElement.style.setProperty(`--site-header-text`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--site-header-box-shadow`, textColorBlack + '80');
      this._doc.documentElement.style.setProperty(`--site-header-highlight-text`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--site-header-highlight`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--site-header-highlight-text-invert`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--site-header-badge-bg`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--site-header-badge-text`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--success-bg-text`, textColorBlack);

      const hexToRgbColr = this.hexToRgb(`#${lighterColor}`)
      this._doc.documentElement.style.setProperty('--accent-bg-light-color', `#${lighterColor}`); //`rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.15)`);
      this._doc.documentElement.style.setProperty(`--site-header-highlight-light`, `#${lighterColor}`);

      const darkerThemeColor = this.LightenDarkenColor(accentColor.replace('#', ''), -40);
      this._doc.documentElement.style.setProperty('--accent-dark-color', `#${darkerThemeColor}`);
      // userId && usersToCheckArr.findIndex(u => u === userId) > -1 ? null : this._doc.documentElement.style.setProperty(`--quest-detail-nav-dark`, `#${darkerThemeColor}`);
      this._doc.documentElement.style.setProperty(`--quest-detail-nav-dark`, `#${darkerThemeColor}`);

      this._doc.documentElement.style.setProperty('--nav-flow-number-bg', `#000000`);
      this._doc.documentElement.style.setProperty('--nav-flow-number-color', `#ffffff`);

      this._doc.documentElement.style.setProperty('--checkbox-selection-color', textColorBlack);
    }

    if (themeColor !== '#E5EAED' && ccc.isLevelAA(themeColor, textColorBlack, 18)) {
      // black is valid on bg 
      // console.log('changeRootColor 169');
      // const darkestThemeColor = this.LightenDarkenColor(bgColor.replace('#', ''), -155);
      //`rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.35)`);
      // this._doc.documentElement.style.setProperty('--btn-text-light', `${textColorBlack}`);
      this._doc.documentElement.style.setProperty('--bg-text-color', textColorBlack);
      this._doc.documentElement.style.setProperty('--avatar-border', textColorBlack);

      const darkWhiteColor = this.LightenDarkenColor(textColorWhite.replace('#', ''), -100);
      this._doc.documentElement.style.setProperty('--bg-text-color-light', `#${darkWhiteColor}`);
      this._doc.documentElement.style.setProperty('--bg-text-color-secondary', `#${darkWhiteColor}`);

      this._doc.documentElement.style.setProperty('--btn-text-color', textColorBlack);

      // this._doc.documentElement.style.setProperty('--primary-bg', 'rgba(255, 255, 255, 0.6)');
      this._doc.documentElement.style.setProperty(`--quest-content-bg`, `rgba(255, 255, 255, 0.1)`);
      this._doc.documentElement.style.setProperty(`--quest-highlight-bg`, 'rgba(255, 255, 255, 0.55)');
      this._doc.documentElement.style.setProperty(`--transparent-white-bg`, 'rgba(255, 255, 255, 0.55)');

      this._doc.documentElement.style.setProperty(`--bg-white-ghost`, 'rgba(255, 255, 255, 0.5)');
      this._doc.documentElement.style.setProperty(`--timeline-bg`, 'rgba(255, 255, 255, 0.5)');

      this._doc.documentElement.style.setProperty('--comment-text-color', textColorBlack);
      this._doc.documentElement.style.setProperty('--bg-milestone', 'rgba(255, 255, 255, 0.55)');

      const darkThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), 10);
      const darkerThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), -36);
      const darkestThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), -50);
      this._doc.documentElement.style.setProperty('--comment-blue-bg', `#${darkestThemeColor}`);
      this._doc.documentElement.style.setProperty('--white-bg-original', `#${darkestThemeColor}`);
      // this._doc.documentElement.style.setProperty('--accent-dark-color', `#${darkestThemeColor}`);
      const _darkThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), 20);
      this._doc.documentElement.style.setProperty(`--feedback-bg`, `#${_darkThemeColor}`);
      this._doc.documentElement.style.setProperty(`--flow-pages-bg`, `#${darkThemeColor}`);
      this._doc.documentElement.style.setProperty(`--search-hover-bg`, `#${darkThemeColor}`);
      this._doc.documentElement.style.setProperty(`--accent-color-secondary-light`, `#${darkThemeColor}`);

      this._doc.documentElement.style.setProperty('--milestone-task-bg', 'rgba(255, 255, 255, 0.55)');

      this._doc.documentElement.style.setProperty(`--tooltip-bg`, `${textColorBlack}`);
      this._doc.documentElement.style.setProperty(`--tooltip-text-color`, `${textColorWhite}`);
      this._doc.documentElement.style.setProperty(`--close-btn-invert-str`, `invert(0)`);

      this._doc.documentElement.style.setProperty(`--payment-block-row`, `rgba(255, 255, 255, 0.3)`);

      this._doc.documentElement.style.setProperty(`--bs-backdrop-bg-custom`, '#000000');

      this._doc.documentElement.style.setProperty(`--read-more-btn-text`, textColorBlack);

      this._doc.documentElement.style.setProperty(`--dots-bg`, `${textColorBlack}`);
      this._doc.documentElement.style.setProperty(`--dots-color`, `${textColorWhite}`);

      this._doc.documentElement.style.setProperty(`--team-option-bg`, `rgba(0, 0, 0, 0.3)`);
      this._doc.documentElement.style.setProperty(`--team-option-bg-dark`, `rgba(0, 0, 0, 0.1)`);
      this._doc.documentElement.style.setProperty(`--lighter-btn-bg`, `rgba(0, 0, 0, 0.3)`);

      this._doc.documentElement.style.setProperty('--primary-bg-secondary', `#${darkerThemeColor}`);
      this._doc.documentElement.style.setProperty('--quest-detail-right', `${themeColor}`);
    }
    
    if (ccc.isLevelAA(accentColor, textColorWhite, 20)) {
      // white is valid
      console.log('changeRootColor 183 white is valid');
      const darkerColor = this.LightenDarkenColor(accentColor.replace('#', ''), -40);
      
      this._doc.documentElement.style.setProperty(`--theme-text-light`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--theme-text-dark`, textColorBlack);
      this._doc.documentElement.style.setProperty('--btn-text-light', textColorWhite);
      this._doc.documentElement.style.setProperty(`--site-header-text`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--site-header-box-shadow`, textColorWhite + '80');
      this._doc.documentElement.style.setProperty(`--site-header-highlight-text`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--site-header-highlight`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--site-header-highlight-text-invert`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--site-header-badge-bg`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--site-header-badge-text`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--success-bg-text`, textColorWhite);

      // const hexToRgbColr = this.hexToRgb(`#${darkerColor}`);
      this._doc.documentElement.style.setProperty('--accent-bg-light-color', `#${darkerColor}`); //`rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.45)`);
      this._doc.documentElement.style.setProperty(`--site-header-highlight-light`, `#${darkerColor}`);

      const darkerThemeColor = this.LightenDarkenColor(accentColor.replace('#', ''), 40);
      this._doc.documentElement.style.setProperty('--accent-dark-color', `#${darkerThemeColor}`);
      // userId && usersToCheckArr.findIndex(u => u === userId) > -1 ? null : this._doc.documentElement.style.setProperty(`--quest-detail-nav-dark`, `#${darkerThemeColor}`);
      this._doc.documentElement.style.setProperty(`--quest-detail-nav-dark`, `#${darkerThemeColor}`);

      this._doc.documentElement.style.setProperty('--nav-flow-number-bg', `#ffffff`);
      this._doc.documentElement.style.setProperty('--nav-flow-number-color', `#000000`);

      this._doc.documentElement.style.setProperty('--checkbox-selection-color', textColorWhite);
    }

    if (themeColor !== '#E5EAED' && ccc.isLevelAA(themeColor, textColorWhite, 20)) {
      // black is valid on bg 
      // console.log('changeRootColor 194');
      // const darkestThemeColor = this.LightenDarkenColor(bgColor.replace('#', ''), -155);
      // this._doc.documentElement.style.setProperty('--btn-text-light', `${textColorBlack}`);
      this._doc.documentElement.style.setProperty('--bg-text-color', textColorWhite);
      this._doc.documentElement.style.setProperty('--avatar-border', textColorWhite);

      const darkWhiteColor = this.LightenDarkenColor(textColorWhite.replace('#', ''), -45);
      this._doc.documentElement.style.setProperty('--bg-text-color-light', `#${darkWhiteColor}`);
      this._doc.documentElement.style.setProperty('--bg-text-color-secondary', `#${darkWhiteColor}`);
      
      this._doc.documentElement.style.setProperty('--btn-text-color', textColorWhite);

      // this._doc.documentElement.style.setProperty('--primary-bg', 'rgba(0, 0, 0, 0.6)');
      this._doc.documentElement.style.setProperty(`--quest-content-bg`, `rgba(0, 0, 0, 0.1)`);
      this._doc.documentElement.style.setProperty(`--quest-highlight-bg`, 'rgba(255, 255, 255, 0.15)');
      this._doc.documentElement.style.setProperty(`--transparent-white-bg`, 'rgba(255, 255, 255, 0.15)');

      this._doc.documentElement.style.setProperty(`--bg-white-ghost`, 'rgba(0, 0, 0, 0.5)');
      this._doc.documentElement.style.setProperty(`--timeline-bg`, 'rgba(0, 0, 0, 0.5)');

      this._doc.documentElement.style.setProperty('--comment-text-color', textColorWhite);
      this._doc.documentElement.style.setProperty('--bg-milestone', 'rgba(255, 255, 255, 0.15)');

      const lightThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), 36);
      this._doc.documentElement.style.setProperty('--comment-blue-bg', `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty('--white-bg-original', `#${lightThemeColor}`);
      const _lightThemeColor = this.LightenDarkenColor(themeColor.replace('#', ''), 45);
      this._doc.documentElement.style.setProperty(`--feedback-bg`, `#${_lightThemeColor}`);
      // this._doc.documentElement.style.setProperty('--accent-dark-color', `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty(`--flow-pages-bg`, `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty(`--search-hover-bg`, `#${lightThemeColor}`);
      this._doc.documentElement.style.setProperty(`--accent-color-secondary-light`, `#${lightThemeColor}`);

      this._doc.documentElement.style.setProperty('--milestone-task-bg', 'rgba(255, 255, 255, 0.15)');

      this._doc.documentElement.style.setProperty(`--tooltip-bg`, `${textColorWhite}`);
      this._doc.documentElement.style.setProperty(`--tooltip-text-color`, `${textColorBlack}`);
      this._doc.documentElement.style.setProperty(`--close-btn-invert-str`, `invert(1)`);

      this._doc.documentElement.style.setProperty(`--payment-block-row`, `rgba(0, 0, 0, 0.3)`);

      this._doc.documentElement.style.setProperty(`--bs-backdrop-bg-custom`, '#a9a9a9');

      this._doc.documentElement.style.setProperty(`--read-more-btn-text`, textColorWhite);

      this._doc.documentElement.style.setProperty(`--dots-bg`, `${textColorWhite}`);
      this._doc.documentElement.style.setProperty(`--dots-color`, `${textColorBlack}`);

      this._doc.documentElement.style.setProperty(`--team-option-bg`, `rgba(255, 255, 255, 0.3)`);
      this._doc.documentElement.style.setProperty(`--team-option-bg-dark`, `rgba(255, 255, 255, 0.1)`);
      this._doc.documentElement.style.setProperty(`--lighter-btn-bg`, `rgba(255, 255, 255, 0.3)`);
      
      document.documentElement.style.setProperty('--primary-bg-secondary', `#${lightThemeColor}`);
      document.documentElement.style.setProperty('--quest-detail-right', `${themeColor}`);
      document.documentElement.style.setProperty('--table-member-bg', `#${this.LightenDarkenColor(themeColor.replace('#', ''), 25)}`);
      document.documentElement.style.setProperty('--table-rank-bg', `#${this.LightenDarkenColor(themeColor.replace('#', ''), 45)}`);
      document.documentElement.style.setProperty('--table-gutter-bg', `#${this.LightenDarkenColor(themeColor.replace('#', ''), 45)}`);
    }

    // if (userId && usersToCheckArr.findIndex(u => u === userId) > -1) {
    //   this._doc.documentElement.style.setProperty(`--quest-header-bg`, navColor);
    //   this._doc.documentElement.style.setProperty(`--quest-detail-nav`, navColor);
      
    //   const _darkerThemeColor = this.LightenDarkenColor(navColor.replace('#', ''), 30);
    //   this._doc.documentElement.style.setProperty(`--quest-detail-nav-dark`, `#${_darkerThemeColor}`);
    // }
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  LightenDarkenColor(col,amt) {
    var usePound = false;
    if ( col[0] == "#" ) {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if ( r > 255 ) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if ( b > 255 ) b = 255;
    else if  (b < 0) b = 0;
    
    var g = (num & 0x0000FF) + amt;

    if ( g > 255 ) g = 255;
    else if  ( g < 0 ) g = 0;

    let color = (g | (b << 8) | (r << 16)).toString(16);
    color = ('000000' + color);
    color = color.substring(color.length - 6, color.length);
    return (usePound?"#":"") + color;
  }

  checkEnteredCustomColor(colorForm, bgColorElem, accentColorElem, rendererServ, showError = true) {
    if (this.isServer) {
      return;
    }
    let defaultAccentColor = '008297';
    let defaultBackgroundColor = '#E5EAED';
    if (colorForm.value.revertCustomColor) {
      colorForm.patchValue({
        accentColor: (defaultAccentColor).replace('#', ''),
        themeColor: defaultBackgroundColor
      }, {emitEvent: false});
    }

    const colors = colorForm.value;
    if (bgColorElem && bgColorElem.nativeElement) rendererServ.setStyle(bgColorElem.nativeElement, 'background', colors.themeColor);
    if (accentColorElem && accentColorElem.nativeElement) rendererServ.setStyle(accentColorElem.nativeElement, 'background', '#' + colors.accentColor);
    
    if (!showError || colorForm.invalid) {
      return false;
    }
    
    colors.accentColor = '#' + (colors.accentColor.replace('#', ''));
    if ((colors.accentColor && colors.accentColor !== '' && colors.accentColor.indexOf('#') < 0) 
      || (colors.themeColor && colors.themeColor !== '' && colors.themeColor.indexOf('#') < 0)) {
      this.notifier.notify('error', 'Color should be a HEX value with a hash(#).');

      return false;
    }

    if (colors.accentColor === colors.themeColor) {
      this.notifier.notify('error', 'Accent and theme colors cannot be same');

      return false;
    }

    if (colors.accentColor.length < 7 || colors.themeColor.length < 7) {
      return;
    }

    const ccc = new ColorContrastChecker();
    /* if (!isFirstTime && 
    //     colors.accentColor && colors.themeColor && 
    //     (
    //       !ccc.isLevelAA(colors.themeColor, colors.accentColor, 20) || 
    //       !ccc.isLevelAA(colors.accentColor, colors.themeColor, 20)
    //     )
      // ) {
      // this.notifier.notify('warning', 'Check contrast – may not meet accessibility standards');
      // this.notifier.notify('warning', 'Accent and theme colors lack sufficient contrast, which may impact readability. Please reconsider your color choices before saving.');

      // return false;
    // }
    */

    if (!colors.revertCustomColor && ((!colors.accentColor || colors.accentColor == '') || (!colors.themeColor || colors.themeColor == ''))) {
      this.notifier.notify('error', 'Accent and theme colors need to be filled');

      return false;
    }

    // changing color
    // console.log('changing color of sample', rendererServ, this.bgColorElem, this.accentColorElem, colors.accentColor, colors.themeColor);
    
    const textColorBlack = '#2e3638';
    const textColorWhite = '#ffffff';
    this._doc.documentElement.style.setProperty(`--sample-primary-bg`, colors.themeColor);
    // this._doc.documentElement.style.setProperty('--sample-white-section-bg', `${colors.themeColor}`);
    this._doc.documentElement.style.setProperty(`--sample-theme-bg-color`, colors.accentColor);

    if (ccc.isLevelAA(colors.themeColor, textColorBlack, 18)) {
      this._doc.documentElement.style.setProperty('--sample-bg-text-color', textColorBlack);
      this._doc.documentElement.style.setProperty(`--sample-quest-content-bg`, `rgba(255, 255, 255, 0)`);
    }

    if (ccc.isLevelAA(colors.themeColor, textColorWhite, 18)) {
      this._doc.documentElement.style.setProperty('--sample-bg-text-color', textColorWhite);
      this._doc.documentElement.style.setProperty(`--quest-content-bg`, `rgba(0, 0, 0, 0.1)`);
    }

    if (ccc.isLevelAA(colors.accentColor, textColorBlack, 18)) {
      const lighterColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), 30);
      // this._doc.documentElement.style.setProperty(`--sample-theme-text-color`, textColorBlack);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-light`, textColorBlack);
      // this._doc.documentElement.style.setProperty(`--sample-theme-text-dark`, textColorWhite);
      this._doc.documentElement.style.setProperty('--sample-btn-text-light', textColorWhite);
      
      const hexToRgbColr = this.hexToRgb(`#${lighterColor}`)
      this._doc.documentElement.style.setProperty('--sample-theme-bg-color-light', `#${lighterColor}`);

      const darkerThemeColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), -40);
      // this._doc.documentElement.style.setProperty('--sample-theme-dark-color', `#${darkerThemeColor}`);
    }

    if (ccc.isLevelAA(colors.accentColor, textColorWhite, 18)) {
      const darkerColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), -30);
      
      // this._doc.documentElement.style.setProperty(`--sample-theme-text-color`, textColorWhite);
      this._doc.documentElement.style.setProperty(`--sample-theme-text-light`, textColorWhite);
      // this._doc.documentElement.style.setProperty(`--sample-theme-text-dark`, textColorBlack);
      this._doc.documentElement.style.setProperty('--sample-btn-text-light', textColorBlack);

      const hexToRgbColr = this.hexToRgb(`#${darkerColor}`)
      // this._doc.documentElement.style.setProperty('--sample-theme-bg-light-color', `rgba(${hexToRgbColr.r}, ${hexToRgbColr.g}, ${hexToRgbColr.b}, 0.15)`);
      this._doc.documentElement.style.setProperty('--sample-theme-bg-color-light', `#${darkerColor}`);

      const darkerThemeColor = this.LightenDarkenColor(colors.accentColor.replace('#', ''), 40);
      // this._doc.documentElement.style.setProperty('--sample-theme-dark-color', `#${darkerThemeColor}`);
    }

    return true;
  }

  deleteUserAccount(userId): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/deleteuser/${userId}`,
      {
        headers: headers
      }
    )
  }

  getAccountPlanMembership(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounttier/planmembership/${userId}`,
      {
        headers: headers
      }
    )
  }

  updateBusinessDetails(payload): Observable<{"Success": boolean}> {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post<{"Success": boolean}>(
      environment.target + environment.context + `/brand/update-info`,
      payload,
      {
        headers: headers
      }
    )
  }

  getPlanUsedMembers(): Observable<PlanMemberUsage> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<PlanMemberUsage>(
      environment.target + environment.context + `/member-used`,
      {
        headers: headers
      }
    )
  }

  getUserDetailsFromUserIds(numberStr: string): Observable<QuestUserInfo[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestUserInfo[]>(
      environment.target + environment.context + `/accounttier/usersbyIds/${numberStr}`,
      {
        headers: headers
      }
    )    
  }

  getQuestDetailsFromQuestIds(numberStr: string): Observable<QuestUsageDetailInfo[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestUsageDetailInfo[]>(
      environment.target + environment.context + `/accounttier/questsbyIds/${numberStr}`,
      {
        headers: headers
      }
    )    
  }
}
