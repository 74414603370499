import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { debounceTime, distinctUntilChanged, distinctUntilKeyChanged } from 'rxjs';

@Component({
  selector: 'app-phone-control',
  templateUrl: './phone-control.component.html',
  styleUrls: ['./phone-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneControlComponent),
      multi: true
    }
  ],
})
export class PhoneControlComponent implements OnInit, ControlValueAccessor {
  @Input('formControl') formControl?: any;

  @Output() countryChanged: EventEmitter<void> = new EventEmitter<void>();
  
  touched = false;

  hasError = false;
  
  phoneVal: string;
  
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];

  CountryISO = CountryISO;

  SearchCountryField = SearchCountryField;

  phoneForm: FormGroup;

  valueWrittenToForm: boolean = false;

  PhoneNumberFormat = PhoneNumberFormat;

  phoneFieldVal: any;

  constructor(private fb: FormBuilder) { 
    this.phoneForm = this.fb.group({
      phone: ['']
    })
  }

  ngOnInit() {
    this.phoneForm.controls.phone.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      ).subscribe(res => {
      this.phoneFieldVal = res;
      
      this.phoneVal = res && res.e164Number ? res.e164Number : '';
      this.hasError = (res && res.number && !res.e164Number) ? true : (this.phoneVal.trim() === '' ? false : 
        ((this.phoneForm.controls.phone.errors && this.phoneForm.controls.phone.errors.validatePhoneNumber && this.phoneForm.controls.phone.errors.validatePhoneNumber.valid !== null) ? !this.phoneForm.controls.phone.errors.validatePhoneNumber.valid : false));

      if (this.hasError) {
        this.valueWrittenToForm = false;
      }

      if (this.formControl && res && res !== '') {
        this.formControl.touched = true;
        if (this.phoneVal && this.phoneVal.length > 9 && !this.hasError) {
          this.valueWrittenToForm = true;
          this.formControl.setValue(this.phoneVal);
        }
      }

      if (res && res.nationalNumber) {
        this.phoneForm.patchValue({
          phone: res.nationalNumber
        }, {emitEvent: false});
      }
    });
  }

  onCountryChange(event: any) {
    this.hasError = false;
    this.phoneVal = '';
    this.onChange(this.phoneVal)
    setTimeout(() => {
      this.countryChanged.emit();
    });
  }

  // Both onChange and onTouched are functions
  onChange: any = () => { };
  onTouched: any = () => {
    this.touched = true;
  }

  // We implement this method to keep a reference to the onChange
  // callback function passed by the forms API
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // We implement this method to keep a reference to the onTouched
  // callback function passed by the forms API
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value: any) {
    if (this.valueWrittenToForm) return false;

    this.phoneVal = value.substring(value.length - 10, value.length);;
    this.phoneForm.patchValue({
      phone: this.phoneVal
    });
  }
  valueChanged() {
  }
}
