import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { QuestLite, QuestLitePrepared } from 'src/app/_interface/quest.types';
import { Brand } from 'src/app/_interface/brand.types';

@Injectable({
  providedIn: 'root',
})
export class NewLandingService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {
  }

  getQuests(questsLimit: number): Observable<QuestLitePrepared[]> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<QuestLite[]>(
      environment.target + environment.context + `/quests/recommended?limit=${questsLimit}`,
      {headers: headers}
    ).pipe(
      map((response: QuestLite[]): QuestLitePrepared[] => {
        return response.map((questLite: QuestLite): QuestLitePrepared => {
          const quest = {
            ...questLite,
            category: questLite.pillar
          };
          delete quest.pillar;

          return quest;
        });
      })
    );
  }

  getBrands(): Observable<Brand[]> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<Brand[]>(
      environment.target + environment.context + `/users/brands`,
      {headers: headers}
    );
  }
}
