import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: 'iframe'
})
export class CachedSrcDirective {

  @Input()
  public get cachedSrc(): any {
    return this.elRef.nativeElement.src;
  }

  public set cachedSrc(src: any) {
    if (src && this.elRef.nativeElement.src !== src.changingThisBreaksApplicationSecurity) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'src', src.changingThisBreaksApplicationSecurity);
    }
  }

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) {
  }
}
