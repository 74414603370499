import {environment} from '../environments/environment';
// import Quill from 'quill';

// let Delta = Quill.import('delta') ;
// let Break = Quill.import('blots/break');
// let Embed = Quill.import('blots/embed');

export const password = {
  minLength: 8,
  maxLength: 40
};
export const shareMessage = {
  minLength: 3,
  maxLength: 300
};
export const timeBeforeTokenExpired = 1800000; // 30 min

export const stripeToken = environment.stripeKey;
export const reCaptchaToken = '6LfDq5MoAAAAABHDZItwU_9WKU34ItTBQrTbAYm3';

/*
// Quill editor line break
function lineBreakMatcher() {
  if (!Quill) return;

  var newDelta = new Delta();
  newDelta.insert({'break': ''});
  return newDelta;
}

class SmartBreak extends Break {
  length () {
    return 1
  }
  value () {
    return '\n'
  }
  
  insertInto(parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref)
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR'

// Quill ? Quill.register(SmartBreak) : null;
Quill.register(SmartBreak);
*/
export const QUILL_CONFIG = {
  toolbar: [
    ['bold', 'italic', {header: 1}, {header: 2}, {list: 'ordered'}, {list: 'bullet'}, 'link', 'video', 'clean']
  ],
  clipboard: {
    matchVisual: false,
    // matchers: [
      // ['BR', lineBreakMatcher] 
    // ]
  },
  counter: null,
  // keyboard: {
  //   bindings: {
  //     linebreak: {
  //       key: 13,
  //       shiftKey: true,
  //       handler: function (range) {
  //         let currentLeaf = this.quill.getLeaf(range.index)[0]
  //         let nextLeaf = this.quill.getLeaf(range.index + 1)[0]

  //         this.quill.insertEmbed(range.index, 'break', true, 'user');

  //         // Insert a second break if:
  //         // At the end of the editor, OR next leaf has a different parent (<p>)
  //         if (nextLeaf === null || (currentLeaf.parent !== nextLeaf.parent)) {
  //           this.quill.insertEmbed(range.index, 'break', true, 'user');
  //         }

  //         // Now that we've inserted a line break, move the cursor forward
  //         this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
  //       }
  //     }
  //   }
  // }
};

export const DEFAULT_SELECT_COUNTRY = 'US';

export const MILESTONE_TITLE_HINTS = ['Milestones', 'Tasks', 'Days', 'Activities'];

export const API_URL = 'https://api.diem.life/';

export const COMMENT_CHARACTER_LIMIT = 1250;

export const LOG_ACTIVITY_POST_CHARACTER_LIMIT = 3000;

export const PAGE_SIZE = 25;

export const MAXIMUM_VIDEO_SIZE = (500 * 1024 * 1024); /** 100 MB */

export const VIDEO_EXTENSION_ALLOWED = ['mov', 'mp4', 'wmv', 'avi', 'hevc'];

export const ORGANIZATION_TYPE_LIST = [{
    label: "Company",
    value: "company"
  }, {
    label: "Individual",
    value: "individual"
  }, {
    label: "Nonprofit",
    value: "non_profit"
  },{
    label: "Government entity",
    value: "government_entity"
}];

export const EMAIL_REGEX_VALIDATOR = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WEBSITE_REGEX_VALIDATOR = /^((https?):\/\/)?(www.)?[a-zA-Z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*/;

export const META_IMAGE_DIEMLIFE = '/assets/images/useful/logo_diemlife_text.webp';

export const GROUP_PLAN_ID_NAME = {
  1: 'DIEMlife',
  2: 'B-ART',
  3: 'NEX',
  4: 'FOREVER YOUNG MOMS',
  5: 'FOREVER YOUNG SENIORS',
  6: 'NEX CORP'
};
