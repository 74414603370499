import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { distinctUntilChanged, map, take } from 'rxjs';
import { BrandConfig } from 'src/app/_interface/brand.types';
import { Quest, StartTeamQuestPayload } from 'src/app/_interface/quest.types';
import { CreateTeam, FundraiserStarted, LoadBrands, LoadTeams, StartFundraiser, TeamCreated } from 'src/app/_interface/store/quest-detail.actions';
import { QuestTeam } from 'src/app/_interface/team.types';
import { User } from 'src/app/_interface/user-profile.types';
import { QuestService } from 'src/app/_services/quest.service';
import { AppState, questDetailState } from 'src/app/_store/app.reducers';
import { TicketTeamsList, TicketUpdateSelectedTeam } from 'src/app/modules/ticket-flow/pages/ticket-flow/store/ticket-flow.actions';

@Component({
  selector: 'app-create-team-fundraise',
  templateUrl: './create-team-fundraise.component.html',
  styleUrls: ['./create-team-fundraise.component.scss']
})
export class CreateTeamFundraiseComponent implements OnInit {
  @Input('fundraising') fundraising: boolean;
  @Input('questTitle') questTitle: string;
  @Input('questId') questId: number;
  @Input('doerId') doerId: number;
  @Input('viewerId') viewerId: number;
  @Input('multiSeller') multiSeller: boolean;
  @Input('questCreatorDetails') questCreatorDetails: User;

  @Input('questData') questData: Quest;

  // @Input('createOnlyTeam') createOnlyTeam: boolean = false;

  submitForm: boolean = false;

  brandsInitialized = false;

  brands: BrandConfig[] = [];

  secondaryBrands: BrandConfig[] = [];
  
  progress: boolean = false;

  inviteMembersAdded: boolean = false;

  fundraisingApiCalled: boolean = false;

  isEditTeam: boolean = false;

  existingTeamData: QuestTeam;

  teamModalChoice: string = 'CreateTeam';

  constructor(private store: Store<AppState>,
    private modalService: NgbModal,
    private questService: QuestService,
    private notifier: NotifierService) {
      this.store.select('quest').subscribe(questRes => {
        // check if editing a team
        if (questRes.createTeamModalConfig && questRes.createTeamModalConfig.teamId) {
          this.isEditTeam = true;
          this.existingTeamData = questRes.createTeamModalConfig;
        }
      })
  }

  ngOnInit() {
    if (this.multiSeller) {
      this.subscribeBrands();
    }

    this.store.dispatch(new LoadBrands(this.questId));
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (this.createOnlyTeam) {
  //     this.teamModalChoice = 'CreateTeam';
  //   }
  // }

  closeModal() {
    this.modalService.dismissAll();
  }

  createTeam() {
    this.submitForm = true;
    
    setTimeout(() => {
      this.submitForm = false;
    }, 10000);
  }

  private subscribeBrands() {
    // this.questBrandsSubscription = 
    this.store.pipe(
      select(questDetailState),
      distinctUntilChanged((before, after) => {
        this.brandsInitialized = null;
        return before.selectedQuestBrands.length === after.selectedQuestBrands.length;
      }),
      map(state => state.selectedQuestBrands)
    ).subscribe(brands => {
      this.secondaryBrands = brands.filter(brand => brand.secondaryRecipient);
      this.brands = brands.filter(brand => !brand.secondaryRecipient);

      this.brandsInitialized = this.brandsInitialized === null;
    });
  }


  startQuestInTeam(payload: StartTeamQuestPayload) {
    if (this.progress) {
      return;
    }
    
    this.progress = true;

    if (this.isEditTeam) {
      // update team data
      // console.log('103', payload);
      let newPayload: QuestTeam = {
        teamId: payload.teamId,
        teamName: payload.teamName,
        teamLogoUrl: payload.teamLogoUrl,
        teamCoverUrl: payload.teamCoverUrl,
        coverCenterX: payload.coverCenterX,
        coverCenterY: payload.coverCenterY,
        coverZoomValue: payload.coverZoomValue
      }

      this.questService.updateTeamInfo(newPayload).subscribe(() => {
        this.store.dispatch(new LoadTeams(this.questId));
        
        this.closeModal();
        },
        error => {
          console.log('There was an issue completing your request. Please try again.', error);
          this.notifier.notify('error', `There was an issue completing your request. Please try again.`);
        }
      );

      return;
    }

    let fundRaisingForm = null;
    if (this.fundraising) {
      let fundraiseTitle;
      if (payload.targetBrandUserId == 1386 || payload.targetBrandUserId == 73) {
        fundraiseTitle = this.multiSeller
        ? payload.teamName + ' raising for General Fund'
        : payload.teamName;
      } else {
        fundraiseTitle = this.multiSeller
        ? payload.teamName + ' raising for ' + this.brands.filter(brand => brand.id === payload.targetBrandUserId)[0].name
        : payload.teamName;
      }

      fundRaisingForm = {
        "questId": this.questData.quest.id,
        "doerId": this.viewerId,
        "brandUserId": payload.targetBrandUserId,
        "targetAmount": payload.targetAmount,
        "currency": "usd",
        "campaignName": fundraiseTitle,
        "secondaryBrandUserId": payload.targetSecondaryBrandUserId,
        "coverImageUrl": null,
        "coverCenterX": null,
        "coverCenterY": null,
        "coverZoomValue": null
      };
      // this.store.dispatch(new CreateTeam(this.questId, payload.teamName, payload.teamLogoUrl, false, true, {
      //   questId: this.questId,
      //   doerId: this.viewerId,
      //   brandUserId: payload.targetBrandUserId,
      //   targetAmount: payload.targetAmount,
      //   currency: 'usd',
      //   campaignName: fundraiseTitle,
      //   secondaryBrandUserId: payload.targetSecondaryBrandUserId,
      //   teamId: null, //to be replaced once create team is success
      //   coverImageUrl: null,
      //   coverCenterX: null,
      //   coverCenterY: null,
      //   coverZoomValue: null
      // }));

      // return;
    }

    this.questService.startTeamAndFundraise({
      "questStartForm": {
          "questMode": this.questData.quest.mode,
          "questId": this.questData.quest.id,
          "questTeamAction": "Create",
          "questTeamName": payload.teamName,
          "questTeamLogoUrl": payload.teamLogoUrl
      },
      "fundRaisingForm": fundRaisingForm ? {...fundRaisingForm} : null
    }).subscribe(res => {
      this.notifier.notify('success', `New team "${payload.teamName}" created`);
      
      this.store.dispatch(new TeamCreated({...res.Team}));

      // adding team to the ticket registration team list 
      this.store.select('ticketFlow').pipe(take(1)).subscribe(ticketState => {
        let _currentList = ticketState.teamList;
        this.store.dispatch(new TicketTeamsList([..._currentList, res.Team]));

        // make the new team selected
        this.store.dispatch(new TicketUpdateSelectedTeam({
          questTeamId: res.Team.teamId,
          questTeamAction: "Join"
        }));
      });

      if (res.fundraisingLink && res.fundraisingLink.id) {
        this.store.dispatch(new FundraiserStarted(res.fundraisingLink));
      }

      this.closeModal();
    }, err => {
      this.notifier.notify('error', "Error creating team.")
    });

    // this.store.dispatch(new CreateTeam(this.questId, payload.teamName, payload.teamLogoUrl, false));
  }

  inviteMembersChange(event) {
    this.inviteMembersAdded = event;
  }

  selectOption(select: string): void {
    this.teamModalChoice = select;
  }
}
