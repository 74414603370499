import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { RequestHeadersService } from './auth-service.types';
import {
  AddImageToMilestonePayload,
  AddLinkPreviewToMilestonePayload,
  AddMilestonePayload,
  AddTaskGroup,
  AddVideoToMilestonePayload,
  MoveTaskPayload,
  TaskGroup
} from './dl-milestones.typings';
import { EnvironmentModel } from './environment-model.types';
import { EnvironmentService } from './environment-service.types';
import { QuestTask } from './quest.types';
import { HTTPStatus } from '../interceptors/loader.interceptor';

export class DlMilestonesService {
  private readonly environment: EnvironmentModel;

  constructor(
    private http: HttpClient,
    private requestHeadersService: RequestHeadersService,
    private environmentService: EnvironmentService,
    private httpStatus: HTTPStatus
  ) {
    this.environment = environmentService.getEnvironment();
  }

  updateMilestonesGroupName(groupId: number, payload: AddTaskGroup): Observable<TaskGroup> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);

    return this.http.post<TaskGroup>(
      this.environment.target + this.environment.context + '/quest-tasks-groups/' + groupId,
      payload,
      {headers: headers}
    );
  }

  removeTaskGroup(groupId: number): Observable<TaskGroup> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.delete<TaskGroup>(
      this.environment.target + this.environment.context + `/quest-tasks-groups/${groupId}`,
      {headers: headers}
    );
  }

  createDefaultGroupWithTasks(questId: number, title: string): Observable<TaskGroup> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post<TaskGroup>(
      this.environment.target + this.environment.context + `/quests/${questId}/task-groups/default`,
      {groupName: title},
      {headers: headers}
    );
  }

  createNewTaskGroup(questId: number, payload: AddTaskGroup): Observable<TaskGroup> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post<TaskGroup>(
      this.environment.target + this.environment.context + `/quests/${questId}/task-groups/new`,
      payload,
      {headers: headers},
    );
  }

  getQuestGroupTasks(questId: number, userId: number): Observable<TaskGroup[]> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.get<TaskGroup[]>(
      this.environment.target + this.environment.context + `/quest-tasks-group/${questId}/${userId}`,
      {headers: headers}
    ).pipe(
      map((taskGroups: TaskGroup[]) => {
        taskGroups.map((group: TaskGroup) => {
          if (group.questTasks) {
            return group.questTasks.map(task => new QuestTask(task));
          }
        });
        return taskGroups;
      })
    );
  }

  moveTask(payload: MoveTaskPayload): Observable<QuestTask> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post<QuestTask>(
      this.environment.target + this.environment.context + `/quest-tasks-groups/tasks/move`,
      {...payload},
      {headers: headers}
    );
  }

  addMilestone(questId: number, payload: AddMilestonePayload): Observable<QuestTask> {
    const headers = this.requestHeadersService.getHeaders();
    const filteredPayload = {
      ...payload,
      groupIndex: payload.groupIndex,
      // task: payload.task,
      questOwnerId: payload.questOwnerId
    } as AddMilestonePayload;
    if (payload.video && payload.video.url) {
      filteredPayload.video = payload.video;
    } else if (payload.linkUrl) {
      filteredPayload.linkUrl = payload.linkUrl;
    } else if (payload.linkedQuestId) {
      filteredPayload.linkedQuestId = payload.linkedQuestId;
    } 
    if (payload.imageUrl) {
      filteredPayload.imageUrl = payload.imageUrl;
    }
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      this.environment.target + this.environment.context + `/quests/${questId}/add-milestone`,
      filteredPayload,
      {headers: headers}
    ).pipe(
      map((milestone: QuestTask) => {
        return new QuestTask(milestone);
      })
    );
  }

  addLinkToMilestone(payload: AddLinkPreviewToMilestonePayload): Observable<QuestTask> {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      this.environment.target + this.environment.context + `/quest-milestones/add-link`,
      payload,
      {headers: headers}
    ).pipe(
      map((newTask: any) => {
        return new QuestTask(newTask);
      })
    );
  }
  addVideoToMilestone(payload: AddVideoToMilestonePayload) {
    return this.editMilestone(payload);
  }

  addImageToMilestone(payload: AddImageToMilestonePayload): Observable<string> {
    const headers = this.requestHeadersService.getHeaders();

    const params = new HttpParams().set('format', 'base64');
    params.append('responseType', 'text' as 'json');

    const data = new FormData();
    data.append('taskId', payload.taskId.toString());
    data.append('taskImage', payload.taskImage);
    data.append('contentType', payload.contentType);

    this.httpStatus.setHttpStatus(true);
    return this.http.post<string>(
      this.environment.target + this.environment.context + `/quest-milestones/add-image`,
      data,
      {
        headers: headers,
        params: params,
        responseType: 'text' as 'json'
      }
    );
  }

  editMilestone(payload) {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      this.environment.target + this.environment.context + '/quest-milestones/edit',
      payload,
      {headers: headers}
    );
  }
  removePreview(taskId: number) {
    const headers = this.requestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.delete(
      this.environment.target + this.environment.context + `/quest-milestones/remove-media/${taskId}`,
      {headers: headers}
    );
  }
}
