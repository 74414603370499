import { Action } from '@ngrx/store';
import { PreparedExploreFiltersResponce } from 'src/app/_interface/explore-page.types';

export const DL_TOGGLE_PILLAR = 'DL_TOGGLE_PILLAR';
export const DL_SWITCH_CATEGORY = 'DL_SWITCH_CATEGORY';
export const DL_SWITCH_TO_ONLY_PILLAR = 'DL_SWITCH_TO_ONLY_PILLAR';
export const DL_SWITCH_PLACE = 'DL_SWITCH_PLACE';
export const DL_SWITCH_SORT_FILTER = 'DL_SWITCH_SORT_FILTER';
export const DL_SELECT_CATEGORY = 'DL_SET_SELECTED_CATEGORIES';
export const DL_DISABLE_SELECTED_CATEGORY = 'DL_DISABLE_SELECTED_CATEGORY';
export const DL_RESET_PAGINATION_STEP = 'DL_RESET_PAGINATION_STEP';
export const DL_RAISE_PAGINATION_STEP = 'DL_RAISE_PAGINATION_STEP';
export const DL_LOWER_PAGINATION_STEP = 'DL_LOWER_PAGINATION_STEP';
export const DL_CLEAR_FILTERS = 'DL_CLEAR_FILTERS';
export const DL_SET_FILTERS = 'DL_SET_FILTERS';


export class DlSetFilters implements Action {
  readonly type = DL_SET_FILTERS;
  constructor(public payload: PreparedExploreFiltersResponce) {}
}
export class DlTogglePillar implements Action {
  readonly type = DL_TOGGLE_PILLAR;
  constructor(public payload: number) {}
}
export class DlSwitchCategory implements Action {
  readonly type = DL_SWITCH_CATEGORY;
  constructor(public payload: number) {}
}
export class DlSwitchToOnlyPillar implements Action {
  readonly type = DL_SWITCH_TO_ONLY_PILLAR;
  constructor(public payload: number) {}
}
export class DlSwitchPlace implements Action {
  readonly type = DL_SWITCH_PLACE;
  constructor(public payload: number) {}
}
export class DlResetPaginationStep implements Action {
  readonly type = DL_RESET_PAGINATION_STEP;
}
export class DlRaisePaginationStep implements Action {
  readonly type = DL_RAISE_PAGINATION_STEP;
  constructor(public payload: number) {}
}
export class DlLowerPaginationStep implements Action {
  readonly type = DL_LOWER_PAGINATION_STEP;
  constructor(public payload: number) {}
}
export class DlClearFilters implements Action {
  readonly type = DL_CLEAR_FILTERS;
}

export type DlExploreActions =  DlSetFilters |
                                DlResetPaginationStep |
                                DlRaisePaginationStep |
                                DlLowerPaginationStep |
                                DlTogglePillar |
                                DlSwitchToOnlyPillar |
                                DlSwitchCategory |
                                DlSwitchPlace |
                                DlClearFilters;
