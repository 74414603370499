// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  root: 'https://dev.diem.life',
  target: 'https://dev.diem.life',
  authApi: 'https://dev.diem.life',
  cdn: 'https://d14diqmeymw9gt.cloudfront.net',
  context: '/api',
  authContext: '/api/v2',
  stripeKey: 'pk_test_exzme3usQ2FbaWxtmAXrFpuZ',
  mapBoxKey: 'pk.eyJ1IjoiZGllbWxpZmUiLCJhIjoiY2s1NGQxbzRmMGNybzNvcGI0bmZidXU1ZyJ9.NiRBYcGFRQmioF4N76_cAw',
  apiurl: 'https://api.diem.life',
  insta_secret: 'a16ce1df0fa4f037b392ce45b0019a78',
  insta_id: '553612075501645',
  fbAppId: '728108551619083',
  googleClientId: '510786853308-7vpftaqb8er7naboothucj9pttba26bt.apps.googleusercontent.com'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
