import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, ViewChild } from '@angular/core';
import { QuestUserInfo } from '../../../_interface/quest.types';
import { ProfileAvatarService } from './profile-avatar.service';

@Component({
  selector: 'app-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileAvatarComponent implements OnInit, OnChanges {
  @Input() avatar: string;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() userName?: string;
  @Input() isBrand: boolean;

  @Input('userInfo') userInfo: QuestUserInfo;

  @ViewChild('headerPhotoElem', {read: ElementRef, static: true}) headerPhotoElem: ElementRef;

  letters: string = null;
  color: string = null;

  profileImageStyle: any = {};

  constructor(private profileAvatarService: ProfileAvatarService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef) {
  }

  getLetters() {
    if (!this.firstName || this.firstName == '') return '';

    if (this.isBrand || !this.lastName) {
      return this.firstName.substr(0, 1);
    }
    return this.firstName.substr(0, 1) + (this.lastName ? this.lastName.substr(0, 1) : '');
  }

  ngOnInit() {
    this.setImageAndPosition();
  }

  ngOnChanges(): void {
    this.setImageAndPosition();
  }

  setImageAndPosition() {
    if (!this.avatar) {
      this.avatar = '/assets/images/logo-black-white.png';
      if (this.userInfo && this.userInfo.plan && (this.userInfo.plan.planGroupId === 3 || this.userInfo.plan.planGroupId === 6)) {
        this.avatar = '/assets/images/case-study/custom-on1/nex-circle-logo.jpg';
      }
      
      this.profileImageStyle = {
        'background': `url('${this.avatar}') 0 0 / cover no-repeat`
      };
      
      this.color = this.profileAvatarService.getColor();
      this.letters = this.getLetters();
    } else {
      // TODO: this code had only jpg and png types listed.  Added gif for now.  Why nothing else?
      const regexp = new RegExp('(.jpeg$)|(.png$)|(.jpg$)|(.gif$)', 'gs');
      if (!this.avatar.match(regexp) && this.avatar.indexOf('assets.diem.life') >= 0) {
        this.avatar = this.avatar + '.jpeg';
      }

      this.profileImageStyle = {
        'background': `url('${this.avatar}') 0 0 / cover no-repeat`
      };

      this.setProfileImagePosition();
    }

    this.cdr.detectChanges();
  }

  setProfileImagePosition() {
    if (!this.userInfo || !this.userInfo.profileZoomValue || !this.userInfo.profileCenterX || !this.userInfo.profileCenterY) return;

    // getting custom width and height of img
    const divTag = document.createElement('div');
    const randomNum = Math.random();
    divTag.setAttribute('id', 'tobe-deleted-profile' + randomNum);
    divTag.setAttribute('style', 'position: absolute; top: 0; left: 0; z-index: 99;');
    const imgTag = document.createElement('img');
    imgTag.onload = (() => {
      imgTag.setAttribute('id', 'tobe-deleted-img' + randomNum);
      imgTag.setAttribute('style', 'width: 100%');
      divTag.appendChild(imgTag);
      this.renderer.appendChild(this.headerPhotoElem.nativeElement, divTag);
 
      const ht = (document.getElementById('tobe-deleted-img' + randomNum) as any).height;
      const wd = (document.getElementById('tobe-deleted-img' + randomNum) as any).width;
      
      (document.getElementById('tobe-deleted-profile' + randomNum) as any).remove();
      
      // calculating position of img
      const coverImagePositionX = Math.abs(( (this.userInfo.profileCenterX * wd)/100 ) - (this.headerPhotoElem.nativeElement.clientWidth / 2));
      const coverImagePositionY = Math.abs(( (this.userInfo.profileCenterY * ht)/100 ) - (this.headerPhotoElem.nativeElement.clientHeight / 2));
      // console.log('87', coverImagePositionX, coverImagePositionY);
      this.profileImageStyle = {
        'background': `url('${this.avatar}') -${coverImagePositionX}px -${coverImagePositionY}px / cover no-repeat`,
        'width': `${this.userInfo.profileZoomValue * 100}%`,
        'height': `${this.userInfo.profileZoomValue * 100}%`
      };
    })
    imgTag.src = (this.avatar as any);

    this.cdr.detectChanges();
  }

}
