import { Action } from '@ngrx/store';
import * as fromMyQuestsState from './my-quests.reducer';

export const MY_QUESTS_UPDATE_STATE = 'MY_QUESTS_UPDATE_STATE';

export class MyQuestsUpdateState implements Action {
  readonly type = MY_QUESTS_UPDATE_STATE;

  constructor(public payload: fromMyQuestsState.State) {}
}

export type MyQuestsActions = MyQuestsUpdateState;
