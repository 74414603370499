import {Component, Input, OnInit} from '@angular/core';
import {TeamAvatarService} from './team-avatar.service';

@Component({
  selector: 'app-team-avatar',
  templateUrl: './team-avatar.component.html',
  styleUrls: ['./team-avatar.component.scss']
})
export class TeamAvatarComponent implements OnInit {
  @Input() avatar: string;
  @Input() teamName: string;
  letters: string = null;
  color: string = null;

  constructor(private profileAvatarService: TeamAvatarService) {
  }

  getLetters() {
    if (!this.teamName || this.teamName == '') return '';

    return this.teamName.substr(0, 1);
  }

  ngOnInit() {
    if (!this.avatar) {
      this.avatar = '/assets/images/logo-black-white.png';
      this.color = this.profileAvatarService.getColor();
      this.letters = this.getLetters();
    } else {
      // TODO: this code had only jpg and png types listed.  Added gif for now.  Why nothing else?
      // const regexp = new RegExp('(.jpeg$)|(.png$)|(.jpg$)|(.gif$)', 'gs');
      // if (!this.avatar.match(regexp) && this.avatar.indexOf('assets.diem.life') >= 0) {
      //   this.avatar = this.avatar + '.jpeg';
      // }
    }
  }

}
