import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface CalendarRangeInfo {
  days: number[];
  months: string[];
  years: {
    from: number;
    to: number;
    value: number[]
  };
}

@Injectable({
  providedIn: 'root'
})
export class BirthdayControlService {
  public CALENDAR_RANGE_INFO: CalendarRangeInfo = {
    days: [],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    years: {
      from: 1901,
      to: 2021,
      value: []
    }
  };
  private readonly _calendarInfo = new BehaviorSubject<CalendarRangeInfo>(this.CALENDAR_RANGE_INFO);
  readonly _calendarInfo$: Observable<CalendarRangeInfo> = this._calendarInfo.asObservable();

  constructor() {
    this.CALENDAR_RANGE_INFO.years.value = this.GenerateYears();
    this.CALENDAR_RANGE_INFO.days = this.GenerateDays();
  }
  private GenerateDays(): number[] {
    let arr = [];
    for (let i = 1; i <= 31; i++) {
      arr.push(i);
    }
    return arr;
  }
  private GenerateYears(): number[] {
    let arr = [];
    for (let i = this.CALENDAR_RANGE_INFO.years.from; i < this.CALENDAR_RANGE_INFO.years.to; i++) {
      arr.unshift(i);
    }
    return arr;
  }
  getInfo() {
    return this.CALENDAR_RANGE_INFO;
  }
  get info(): Observable<CalendarRangeInfo> {
    return this._calendarInfo$;
  }
}
