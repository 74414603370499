import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, distinctUntilKeyChanged, map, tap } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/_interface/notification.types';
import * as fromApp from './../../../_store/app.reducers';
import * as fromProfile from './../../pages/profile/store/profile.reducer';
import * as NotificationsActions from './store/notifications.actions';
import * as fromNotifications from './store/notifications.reducer';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  activityStatusSubscriber: Subscription;
  activityStatus = false;

  notificationsCount: number = 0;

  @Input('userId') userId: number;

  constructor(
    private store: Store<fromApp.AppState>,
    private activeModal: NgbActiveModal,
    private notificationsService: NotificationsService
  ) {
  }

  ngOnInit() {
    // this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
    //   this.activityStatus = status ? status : null;
    // });
    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('count'),
        map((state) => state.count),
        tap(count => {
          this.notificationsCount = count;
        })
      )
      .subscribe();
  }

  closeNotifications() {
    this.store.dispatch(new NotificationsActions.HideNotifications());
    this.activeModal.close();
  }

  markAllNotificationRead() {
    this.notificationsService.markAllAsRead(this.userId)
      .subscribe(res => {
        this.store.dispatch(new NotificationsActions.MarkAllNotificationRead())
      }, err => {
        console.log('e', err)
      })
  }

}
