import { Injectable } from '@angular/core';
import 'rxjs-compat/add/observable/of';

@Injectable({
  providedIn: 'root',
})
export class LogActivityService {

  constructor() {
  }

  convertValueToGeneric(type: string, value: number, selectedUnit?: number): number {
    switch (type) {
      case 'volume':
        let _newValueVol = value;

        // 'oz'
        if (selectedUnit === 6) {
          _newValueVol = _newValueVol * 0.0295735;
        }

        return _newValueVol;
      
      case 'distance':
        let newValueDist = value;

        if (selectedUnit === 7) {
          // 'mi'
          newValueDist = newValueDist * 1609.34;
        } else if (selectedUnit === 8) {
          // 'km'
          newValueDist = newValueDist * 1000;
        } else if (selectedUnit === 9) {
          // 'ft'
          newValueDist = newValueDist * 0.3048;
        }

        return newValueDist;
      
      case 'elevation':
        // convert to meters
        let newValueEle = value;

        if (selectedUnit === 9) {
          // 'ft'
          newValueEle = newValueEle * 0.3048;
        }

        return newValueEle;

      case 'amount':
        let newValueAmt = value;
        // convert to cents
        newValueAmt = newValueAmt * 100;

        return newValueAmt;

      default:
        return value;
    }
  }

  convertTimeToGeneric(time: {hour: number, minute: number, second: number}): number {
    return (Number(time.hour || 0) * 60 * 60) + (Number(time.minute || 0) * 60) + + Number(time.second || 0);
  }

  convertGenericToNormal(type: string, value: number | string, selectedUnit?: number): string {
    switch (type) {
      case 'volume':
        // converting to oz
        return Number((Number(value) * 33.814).toFixed(2)) + 'oz';
      
      case 'distance':
        // convert to miles
        return Number((Number(value) * 0.000621371).toFixed(2)) + 'mi';
      
      case 'elevation':
        // convert to ft
        return Number((Number(value) * 3.28084).toFixed(2)) + 'ft';

      case 'amount':
        // convert to usd
        return 'USD $' + Number((Number(value) / 100).toFixed(2));

      case 'duration':
        let attVal = (value + '').split(':');
        let newVal = '';
        
        attVal.length === 3 ? newVal = ((Number(attVal[0]) > 0 ? (Number(attVal[0]) + 'h ') : '')  + (Number(attVal[1]) > 0 ? (Number(attVal[1]) + 'm ') : '') + (Number(attVal[2]) > 0 ? (Number(attVal[2]) + 's') : '')) 
          : 
          (attVal.length === 2 ? newVal = ((Number(attVal[0]) > 0 ? (Number(attVal[0]) + 'h ') : '')  + (Number(attVal[1]) > 0 ? (Number(attVal[1]) + 'm ') : '')) : null);
        
          return newVal + '';
        // const h = Math.floor(value / 3600);
        // const m = Math.floor(value % 3600 / 60);
        // const s = Math.floor(value % 3600 % 60);

        // const hDisplay = h > 0 ? h.toFixed(1) : "";
        // const mDisplay = m > 0 ? m.toFixed(1) : "";
        // const sDisplay = s > 0 ? s.toFixed(1) : "";
        // return hDisplay + 'h ' + mDisplay + 'm ' + sDisplay + 's';

      default:
        return value + '';
    }

  }
}
