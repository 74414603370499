import { Action } from '@ngrx/store';
import { AccountColor } from './account.reducer';

export const USER_ACCOUNT_COLORS = 'USER_ACCOUNT_COLORS';
export const GET_USER_ACCOUNT_COLORS = 'GET_USER_ACCOUNT_COLORS';

export class SetAccountColors implements Action {
  readonly type = USER_ACCOUNT_COLORS;
  
  constructor(public payload: AccountColor) {
  }
}

export class GetAccountColors implements Action {
  readonly type = GET_USER_ACCOUNT_COLORS;

  constructor(public payload: { userId: number }) {
  }
}

export type AccountActions = SetAccountColors |
                              GetAccountColors;
