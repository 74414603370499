import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReaquestHeadersService } from 'src/app/_services/requestHeaders.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MembersService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}

  sendMessageAll(questId: number, message: string, subject?: string, group?: string, plain: boolean = false): Observable<void> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<void>(
      environment.target + environment.context + `/quest/doers/message-all/${questId}`,
      { subject, message, group, plain },
      { headers: headers }
    );
  }

  // setDoersStatusRelativeToUser(doers: QuestDoer[]) {
  //   return this.store.select('userInfo').subscribe(userInfoState => {
  //     if (userInfoState.hasGotCurrentFriends && userInfoState.hasGotFriendsNoAction) {
  //       let currentFriends = userInfoState.currentFriends;
  //       let pendingFriendsNoAction = userInfoState.friendsNoAction;

  //       const currentFriendsIds: any = {};
  //       const pendingFriendsNoActionIds: any = {};

  //       if (currentFriends instanceof Array) {
  //         currentFriends.forEach(friend => {
  //           currentFriendsIds[friend.id] = true;
  //         });
  //       }

  //       if (pendingFriendsNoAction instanceof Array) {
  //         pendingFriendsNoAction.forEach(friend => {
  //           pendingFriendsNoActionIds[friend.id] = true;
  //         });
  //       }

  //       doers.forEach((doer: QuestDoer) => {
  //         if (currentFriendsIds[doer.userId]) {
  //           doer.status = 'isAccepted';
  //         } else if (pendingFriendsNoActionIds[doer.userId]) {
  //           doer.status = 'isRequested';
  //         } else {
  //           doer.status = 'noAction';
  //         }
  //       });

  //       return doers;
  //     }
  //   });

    // return forkJoin([
    //   this.friendsService.getCurrentFriends(),
    //   this.friendsService.getPendingFriendsNoAction()
    // ]).pipe(map(([currentFriends, pendingFriendsNoAction]) => {
    //   const currentFriendsIds: any = {};
    //   const pendingFriendsNoActionIds: any = {};

    //   if (currentFriends instanceof Array) {
    //     currentFriends.forEach(friend => {
    //       currentFriendsIds[friend.id] = true;
    //     });
    //   }

    //   if (pendingFriendsNoAction instanceof Array) {
    //     pendingFriendsNoAction.forEach(friend => {
    //       pendingFriendsNoActionIds[friend.id] = true;
    //     });
    //   }

    //   doers.forEach((doer: QuestDoer) => {
    //     if (currentFriendsIds[doer.userId]) {
    //       doer.status = 'isAccepted';
    //     } else if (pendingFriendsNoActionIds[doer.userId]) {
    //       doer.status = 'isRequested';
    //     } else {
    //       doer.status = 'noAction';
    //     }
    //   });
    //   return doers;
    // }));
  // }
}
