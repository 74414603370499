import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optiImage'
})
export class OptiImagePipe implements PipeTransform {

  transform(value: any, size: string): any {
    return value.replace(/\.(gif|jpg|jpeg|tiff|png)$/i, format => {
      return (size ? '_' + size : '') + format;
    });
  }

}
