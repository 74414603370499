import * as AccountActions from './account.actions';

export interface AccountColor {
  themeColor: string;
  accentColor: string;
  revertCustomColor?: boolean;
}

export interface State {
  accentColor: string;
  themeColor: string;
  revertCustomColor?: boolean;
}

export const initialState: State = {
  accentColor: '',
  themeColor: '',
  revertCustomColor: false
};

export function AccountReducer(state = initialState, action: AccountActions.AccountActions) {
  switch (action.type) {
    case (AccountActions.USER_ACCOUNT_COLORS):
      return {
        ...state,
        accentColor: action.payload.accentColor,
        themeColor: action.payload.themeColor
      };
    default:
      return state;
  }
}
