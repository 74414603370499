import { Action } from '@ngrx/store';
import { State, CreditCard, BankAccount } from './payments.reducer';

export const SET_PAYMENTS_INFO = 'SET_PAYMENTS_INFO';
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';
export const REMOVE_CREDIT_CARD = 'REMOVE_CREDIT_CARD';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const REMOVE_BANK_ACCOUNT = 'REMOVE_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_MESSAGE = 'ADD_BANK_ACCOUNT_MESSAGE';

export class AddCreditCard implements Action {
  readonly type = ADD_CREDIT_CARD;
  constructor (public payload: CreditCard) {}
}
export class SetPaymentsInfo implements Action {
  readonly type = SET_PAYMENTS_INFO;
  constructor (public payload: State) {}
}
export class RemoveCreditCard implements Action {
  readonly type = REMOVE_CREDIT_CARD;
  constructor (public payload: number) {}
}
export class AddBankAccount implements Action {
  readonly type = ADD_BANK_ACCOUNT;
  constructor (public payload: BankAccount) {}
}
export class RemoveBankAccount implements Action {
  readonly type = REMOVE_BANK_ACCOUNT;
  constructor (public payload: number) {}
}

export class AddBankAccountMessage implements Action {
  readonly type = ADD_BANK_ACCOUNT_MESSAGE;

  constructor(public payload: string) {}
}

export type PaymentsActions = SetPaymentsInfo | AddCreditCard | RemoveCreditCard | RemoveBankAccount | AddBankAccount
  | AddBankAccountMessage;
