import {Injectable} from '@angular/core';
import {ReaquestHeadersService} from 'src/app/_services/requestHeaders.service';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { User } from 'src/app/_interface/user-profile.types';

@Injectable({
  providedIn: 'root',
})
export class FriendsService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}

  // getCurrentFriends (): Observable<User[]> {
  //   const headers = this.reaquestHeadersService.getHeaders();
  //   return this.http.get<User[]>(
  //     environment.target + environment.context + `/getcurrentfriends`,
  //     { headers: headers }
  //   );
  // }

  // getPendingFriendsToAccept (): Observable<User[]> {
  //   const headers = this.reaquestHeadersService.getHeaders();
  //   return this.http.get<User[]>(
  //     environment.target + environment.context + `/getpendingfriendstoaccept`,
  //     { headers: headers }
  //   );
  // }

  // getPendingFriendsNoAction (): Observable<User[]> {
  //   const headers = this.reaquestHeadersService.getHeaders();
  //   return this.http.get<User[]>(
  //     environment.target + environment.context + `/getpendingfriendsnoaction`,
  //     { headers: headers }
  //   );
  // }

  sendFriendRequest (friendId): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/sendfriendrequest`,
      { friendId: friendId },
      { headers: headers }
    );
  }

  acceptFriendRequest (friendId): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/acceptfriendrequest`,
      { friendId: friendId },
      { headers: headers }
    );
  }

  cancelFriendRequest (friendId): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/declinefriendrequest`,
      { friendId: friendId },
      { headers: headers }
    );
  }

  removeFriend (friendId): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/removefriend`,
      { friendId: friendId },
      { headers: headers }
    );
  }
}
