import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subject} from 'rxjs';
import { BrandConfig } from 'src/app/_interface/brand.types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrandListComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandListComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() brandsList: BrandConfig[];
  @Input() placeholder: string;
  @Input() disableDefaults: boolean = false;

  @Output() onBrandSelected: EventEmitter<number> = new EventEmitter();

  eventsSubject: Subject<void> = new Subject<void>();
  touched: boolean = false;
  disabled: boolean = false;
  selectedId: number;
  generalFundID = environment.production ? 73 : 1386;
  generalFundData: BrandConfig = {
    id: -1,
    name: 'General Fund',
    nonProfit: false,
    logoUrl: '/assets/images/logo-black-white.png',
    siteUrl: null,
    secondaryRecipient: false
  }

  constructor(private cdr: ChangeDetectorRef) {

  }

  propagateChange = (_: any) => {
  };
  setTouched: any = () => {
    this.touched = true;
  };

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.disableDefaults) {
      this.generalFundData.id = this.generalFundID;
      this.brandsList.unshift(this.generalFundData);
    }
    
    if (changes.brandsList && changes.brandsList.previousValue !== changes.brandsList.currentValue) {
      this.eventsSubject.next();
    }

    if (this.brandsList && this.brandsList.length === 1) {
      this.selectBrand(this.brandsList[0]);
      this.eventsSubject.next();
    }

    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.setTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // console.log('Brands select disabled state', isDisabled);
    this.disabled = isDisabled;
    setTimeout(() => {
      this.eventsSubject.next();
    });
  }

  writeValue(value: string): void {
    // console.log('Brand set to ' + value);
    if (value) {
      this.selectedId = parseInt(value, 10);
      setTimeout(() => {
        this.eventsSubject.next();
      });
    }
  }

  selectBrand(brandValue: {id: number | string}) {
    const value = brandValue.id;
    this.setTouched();
    this.selectedId = (id => id < 0 ? null : id)(parseInt((value + ''), 10));
    this.propagateChange(this.selectedId);
    this.onBrandSelected.emit(this.selectedId); 
    this.closedDropdown();

    this.cdr.detectChanges();
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }
}
