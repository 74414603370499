import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Directive({
  selector: '[toggle-directive]'
})
export class ToggleDirective {
  $th: JQuery;
  // tslint:disable-next-line:no-output-rename
  @Output('toggle-directive-event') toggleDirectiveEvent = new EventEmitter<boolean>();

  constructor(private el: ElementRef) {
    this.$th = $(this.el.nativeElement);
  }

  @HostListener('document:click', ['$event.target']) onClick(target) {
    this.toggleClass($(target).closest(this.$th).length === 0);
  }
  private toggleClass(res: boolean): void {
    if (res) {
      this.toggleDirectiveEvent.emit();
    }
  }

}
