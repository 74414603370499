import {User} from './user-profile.types';
import {LeaderboardAttribute} from './leaderboard.types';
import {MapLocation} from './map.types';
import { QuestComment, CommentLikeDetail } from './comment.types';
import { AccountPlan } from './account-tier.types';

export interface QuestLiteNotFilled {
  id: number;
  photo: string;
  questFeed: string;
  title: string;
  views: number;
  user: UserLite;
  dateCreated: number;
  shortDescription: string;
  shareUrl: string;
  seoSlugs: SEOSlugs;
  startBtnDisabled: boolean;
}

export interface QuestLite extends QuestLiteNotFilled {
  pillar: string;
}

export interface QuestLitePrepared extends QuestLiteNotFilled {
  isTemplate?: boolean;
  templateType?: string;
  category?: string;
}

export interface UserLite {
  id: number;
  firstName: string;
  lastName: string;
  profilePictureURL: string;
}

export interface SEOSlugs {
  questId: number;
  userId: number;
  questTitleSlug: string;
  userNameSlug: string;
  seoFriendlyUrl: string;
  shortUrl: string;
}

export interface StartQuestForm {
  questId: number;
  referrerId?: number;
  questMode?: string;
  questTeamAction?: string;
  questTeamId?: number;
  questTeamName?: string;
  questTeamCoverUrl?: string;
  questTeamLogoUrl?: string;
  point?: MapLocation;
}

export interface RealtimeQuestForm {
  questId: number;
  image?: string;
  comment?: string;
  attributes?: Attributes[];
  completeMilestones?: boolean;
  coordinates?: MapLocation;
  withGeoLocation?: boolean;
}

export interface Attributes {
  attributeId: number;
  attributeValue: string;
  attributeTag?: string;
}

export interface UserInfoBrand {
  name: string;
  id: number;
  address: {
    id: number;
    country: string;
    lineOne: string;
    lineTwo: string;
    city: string;
    state: string;
    zip: string;
  };
  orgType: string;
  phone: string;
  website: string;
  firstName?: string;
  lastName?: string;
}

export interface QuestUserInfo {
  id: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  userName: string;
  isUserBrand: string | boolean;
  lastLogin: number;
  active: boolean;
  emailValidated: boolean;
  createdOn: number;
  updatedOn: number;
  profilePictureURL: string;
  coverPictureURL: string;
  country: string;
  zip: string;
  missionStatement: string;
  receiveEmail: string | boolean;
  userNonProfit: boolean;
  absorbFees: boolean;
  identifier: string;
  stripeEntityId: number;
  secondaryEmail: string;
  accountTier: AccountPlan; /*this is set by us on Frontend to the owner's profile info */
  plan: AccountPlan; /* this is returned by the API for the user */
  themeColor: string;
  accenColor: string;
  hasStripeEntity?: boolean;
  billingEnabled?: boolean;
  paymentsSupported?: boolean;
  payoutsSupported?: boolean;
  realtimeQuestId?: number;
  realtimeUserId?: number;
  allowCustomColor?: boolean;
  brand?: UserInfoBrand;
  centerX?: number;
  centerY?: number;
  zoomValue?: number;
  profileZoomValue?: number;
  profileCenterX?: number;
  profileCenterY?: number;
}

export interface QuestInfo {
  privacyLevel: string;
  id: number;
  questFeed: string;
  pillar: string;
  sharedCount: number;
  savedCount: number;
  commentCount: number;
  views: number;
  status: number;
  createdBy: number;
  origCreatedBy: number;
  dateCreated: number;
  modifiedBy: number;
  dateModified: number;
  title: string;
  metaTags: any;
  notes: any;
  photo: string;
  type: string;
  user: QuestUserInfo;
  admins: string[];
  leaderboardAttributes: LeaderboardAttribute[];
  version: string;
  fundraising: boolean;
  fundraisingBarHidden: boolean;
  copyAllowed: boolean;
  leaderboardEnabled: boolean;
  mode: string;
  copyProtectionEnabled: boolean;
  startBtnDisabled: boolean;
  backBtnDisabled: boolean;
  templateType: string;
  isTemplate: boolean;
  isDraft: boolean;
  showBackingAmount: boolean;
  questVideoUrl?: string;
  shortDescription?: string;
  surveysEnabled?: boolean;
  geoTriggerEnabled?: boolean;
  directionsType?: string;
  realtime?: boolean;
  tippingEnabled?: boolean;
  monthlyDonationEnabled?: boolean;
  multiSellerEnabled?: boolean;
  multiTeamsEnabled?: boolean;
  isQuestFundraiser?: boolean;
  themeColor?: string;
  accentColor?: string;
  editable?: boolean;
  starred?: boolean;
  coverZoomValue: number;
  coverCenterX: number;
  coverCenterY: number;
  categoryCode: string;

  description?: string; //description from the static 
}

export interface Quest {
  quest: QuestInfo;
  user: QuestUserInfo;
  activityInfo?: QuestActivity;
  viewerActivityInfo?: QuestActivity;
  questImages: QuestGalleryImage[];
  questTasks: QuestTask[];
  usersDoing: QuestDoer[];
  megaQuests: QuestLite[];
  event: QuestEvent;
  questShareUrl: string;
  userHasSaved: boolean;
  userHasFollowed: boolean;
  backingAllowed: boolean;
  hasEvent: boolean;
  isTeamPage: boolean;
  viewerTeamId?: number;
  doerTeamId?: number;
  completionPercentage?: number;
  questVideoUrl?: string;
  usersSavedCount: number;
  surveysEnabled?: boolean;
  questMapView?: any;
  hasMapRoute: boolean;
  isDoerPurchasedTickets?: boolean;
  isRegisterButtonEnabled?: boolean;
  isMegaQuest?: boolean;
  isParentQuest?: boolean;
  isQuestFundraiser?: boolean;
  questFromEdgeDetail?: any;
  brand?: {
    name: string;
    id: number;
    firstName: string;
    lastName: string;
  };
  title?: string;
}

export interface MilestoneVideo {
  videoId: number;
  videoUrl: string;
  thumbnailUrl: string;
}

export interface MilestoneCompletion {
  userTriggeredId: number;
  dateTriggered: number;
  completed: boolean;
  geoPointInArea: boolean;
  geoPoint: MapLocation;
}

export interface MilestoneLinkPreview {
  title: string;
  description: string;
  image: string;
  url: string;
}

export class QuestTask implements NewMilestone {
  public readonly id: number;
  public readonly questId: number;
  public readonly userId: number;
  public readonly linkedQuestId: number;
  public readonly createdDate: number;
  public readonly lastModifiedDate: number;
  public readonly taskCompletionDate: number;

  public readonly geoPoint: MapLocation;
  public readonly geoMarker: string;
  public readonly completedGeoMarker: string;
  public readonly lastCompletion: MilestoneCompletion;
  public readonly linkedQuest: QuestLite;

  public video: any;
  public embeddedVideo: MilestoneVideo;
  public linkUrl: string;
  public imageUrl: string;
  public linkPreview?: MilestoneLinkPreview;

  public title: string;
  public task: string;
  public activityType: any;
  public order: number;

  public isTaskCompleted: boolean;
  public taskCompleted: string;
  public loading? = false;
  public activities: ({activityName: string; id: number; pillarName: string;})[];

  public activityRecordListId: number;
  public asActivityRecordListPoints: number;

  public videoUrl: string;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public set isLoading(value: boolean) {
    this.loading = value;
  }
  
  public get created(): Date {
    return this.createdDate ? new Date(this.createdDate) : null;
  }

  public get modified(): Date {
    return this.lastModifiedDate ? new Date(this.lastModifiedDate) : null;
  }

  public get completed(): Date {
    return this.taskCompletionDate ? new Date(this.taskCompletionDate) : null;
  }

}

export class QuestMapData {
  public id: number;
  public geoPoint: MapLocation;
  public pinText: string;
  public isCompleted: boolean;
  public location?: string;
  public type?: string; //can be: team, racer, user, milestone, aggregate
  public ids?: number[]; //only for aggregate pins
  public routeWaypoint?: boolean;
  public routeIndex?: string;

  constructor(data: any) {
    Object.assign(this, data);
  }
}

export interface QuestMapRoute {
  id: number;
  questId: number;
  name: string;
  description: string;
  points: QuestMapRoutePoint[];
  distance: number;
}

export interface QuestMapRoutePoint {
  pointId: number;
  geoPoint: MapLocation;
  segment: number;
  altitude: number;
  sequence: number;
}

export interface QuestDoer {
  questId: number;
  userId: number;
  teamId?: number;
  firstName: string;
  lastName: string;
  isUserBrand: boolean;
  userBrand?: boolean;
  userName: string;
  userFullName: string;
  backerDisplayName?: string;
  profilePictureURL: string;
  completeTasksCount: number;
  totalTasksCount: number;
  repeatsCount: number;
  percentage?: number;
  status?: string;
  memberStatus?: string[];
  memberStatusObj?: { [key: string]: boolean };
  sortingScore: number;
  isAnonymous?: boolean;
  anonymous?: boolean;
  amountBacked: number[];
  amountBackedSum?: number;
  geopoint: MapLocation;
  location: string;
  zipCode: string;
}

export interface QuestActivity {
  questId: number;
  userId: number;
  status: string;
  mode: string;
  repeatable: boolean;
  repeatCount: number;
}

export interface QuestGalleryImage {
  caption: string;
  createdDate: number;
  creator: User;
  id: number;
  lastModifiedDate: number;
  questImageUrl: string;
  questImageDimensions: string;
  questVideoUrl: string;
  imageURL?: string;
  videoURL?: string;
  isVideo?: boolean; //set by FE code itself
}

export interface NewMilestone {
  activityType: any; //TODO: Replace any with number or string once BE is fixed
  task: string;
  title: string;
}

export interface QuestEvent {
  happening: Happening;
  registerStatus: RegisterStatus;
  product: ExportedProduct;
  addOns: AddOn[];
}

export interface ParticipantFields {
  id: number;
  hasName: boolean;
  hasEmail: boolean;
  hasPhone: boolean;
  hasGender: boolean;
  hasBirthDate: boolean;
  hasAddress: boolean;
  hasEmergency: boolean;
}

export interface Happening {
  id: number;
  participantFields: ParticipantFields;
  active: boolean;
  waiver: string;
  registrationTemplate: string;
  showDiscounts: boolean;
  showWaiver: boolean;
  happeningDate: number;
}

export interface RegisterStatus {
  date: number;
  status: EventStatus;
}

export enum EventStatus {
  REGISTER_START = 'REGISTER_START',
  REGISTER_PROGRESS = 'REGISTER_PROGRESS',
  REGISTER_ENDED = 'REGISTER_ENDED',
  EVENT_ENDED = 'EVENT_ENDED'
}

export interface AddOn2 {
  id: number;
  addOnType: string;
}

export interface AddOn {
  addOn: AddOn2;
  product?: any;
}

export interface ExportedProduct {
  id: number;
  name: string;
  description: string;
  active: boolean;
  variants: ExportedProductVariant[];
  questId: number;
  registrationTemplate: number;
  showDiscounts: boolean;
  eventDate: number;
}

export interface ExportedProductVariant {
  id: string;
  price: number;
  platformFee: number;
  currency: string;
  active: boolean;
  attributes: Map<string, string>;
  fairPrice?: number;
  quantity?: number;
  isUnlimited?: boolean;
}

export interface LoggedActivityImages {
  imageURL: string;
  videoURL: string;
}

export interface LoggedActivity {
  actvityRecordValueId: number;
  images: LoggedActivityImages[];
  title: string;
  comment: string;
  attributeName: string;
  attributeValue: any;
  abbreviation: string;
  unitNamePlural: string;
  unitNameSingular: string;
  activityName: string;
  tags: string[];
  userFirstName: string;
  userLastName: string;
  userName: string;
  userImageUrl: string;
  userId: number;
  pillarName: string;
  creationDate: string;
  creationDateTime: string;
  userComments: QuestComment[];
  likes: CommentLikeDetail;
  email: string;
  updated: boolean;
  totalComments: number
  isAnonymous: boolean;
  displayName: string;

  completedValue: boolean;
  countValue: number;
  distanceValue: number;
  durationValue: number;
  repsValue: number;
  volumeValue: number;
  amountValue: number;
  elevationValue: number;
  
  activityClass?: string; //custom var to be used internally
  brand?: any;
  imageURL?: string;
}

export interface SelectedActivityForTask {
  id: number;
  description: string;
  name: string;
  createdBy: string | number;
  createdDate: string;
  modifiedBy: string | number;
  modifiedDate: string;
  pillarId: number;
}

export interface Activity {
  id: number;
  activityName: string;
}

export interface QuestUsageDetailInfo {
  name: string;
  photo: string;
  id: number;
}

interface StartFundraiserPayload {
  campaignName?: string;
  targetAmount?: number;
  targetBrandUserId?: number;
  targetSecondaryBrandUserId?: number;
}

export interface StartTeamQuestPayload extends StartFundraiserPayload {
  teamId?: number;
  teamName: string;
  teamLogoUrl: string;

  // for teamquest
  teamCoverUrl: string;
  coverCenterX: string;
  coverCenterY: string;
  coverZoomValue: string;
}

export interface QuestDropdownContext {
  id: number;
  title: string;
  shareUrl: string;
  copyable: boolean;
  followed: boolean;
  saved: boolean;
  active: boolean;
  startButtonDisabled: boolean;
  backBtnEnabled: boolean;
  isTemplate?: boolean;
  editable?: boolean;
  starred?: boolean;
  privacyLevel?: string;
  mode?: string;
  createdBy?: number;
  hasEvent?: boolean;
  isQuestFundraiser?: boolean;
  isMegaQuest?: boolean;
  questTasks?: any;
  geoTriggerEnabled?: boolean;
  isDraft?: boolean;
}

export interface MemberActivity {
  userName: string;
  userFirstName: string;
  userLastName: string;
  userId: number;
  imageURL: string;
  rank: number;
  totalActivities: number;
  time: string;
  count: number;
  rankDouble: number;
  value: string;
  caclulatedRank: number;
  totalSteps: number;
  totalPoints: number;
  totalElevation: number;
  isAnonymous: boolean;
  activities: LoggedActivity[];
  anonymous: boolean;

  // for the member api
  geopoint: {latitude: number; longitude: number;}
  memberStatus: string[];
  amountBacked: number[];
  amountBackedSum?: number;
  memberStatusObj?: any;

  // can be removed
  activityName: string;
  attributeValue: string;
  attributeId: number;
}

export interface QuestMembersApi {
  totalMemberCount: number;
  members: MemberActivity[];
}

export interface QuestTaskGroup {
  groupName: string;
  groupOrder: number;
  groupOwnerId: number;
  id: number;
}

export interface IsQuestMemberResponse {
  isRegistered: boolean;
  isMember: boolean;
  isDonor: boolean;
  id?: number;
  userId?: number;
  questId?: number;
  status?: string;
  mode?: string;
  teamId?: number;
}