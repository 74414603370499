import * as MyQuestsActions from './my-quests.actions';


export interface State {
  active: number;
  saved: number;
  completed: number;
}

export const initialState: State = {
  active: 0,
  saved: 0,
  completed: 0
};
export function MyQuestsReducer( state = initialState, action: MyQuestsActions.MyQuestsUpdateState ) {
  switch (action.type) {
    case (MyQuestsActions.MY_QUESTS_UPDATE_STATE):
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
