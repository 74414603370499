import { Action } from '@ngrx/store';
import {
  MoveTaskPayload,
  PatchMilestonePayload,
  TaskGroup,
  TaskMovedNotification,
  TaskPreviewPayload
} from './dl-milestones.typings';
import { QuestTask } from './quest.types';
export const SET_MILESTONES = 'SET_MILESTONES';
export const MOVE_MILESTONE = 'MOVE_MILESTONE';
export const ADD_MILESTONE = 'ADD_MILESTONE';
export const REMOVE_MILESTONE = 'REMOVE_MILESTONE';
export const CREATE_DEFAULT_TASK_GROUP = 'CREATE_DEFAULT_TASK_GROUP';
export const UPDATE_MILESTONE_GROUP = 'UPDATE_MILESTONE_GROUP';
export const UPDATE_MILESTONE_TASK = 'UPDATE_MILESTONE_TASK';
export const UPDATE_MILESTONE_TASK_COMPLETION = 'UPDATE_MILESTONE_TASK_COMPLETION';
export const UPDATE_TASK_POSITION = 'UPDATE_TASK_POSITION';
export const TASK_POSITION_UPDATED = 'TASK_POSITION_UPDATED';
export const DL_MILESTONES_CLEAR_DATA = 'DL_MILESTONES_CLEAR_DATA';
export const ADD_QUEST_LINK = 'ADD_QUEST_LINK';
export const ADD_MILESTONE_IMAGE_URL = 'ADD_MILESTONE_IMAGE_URL';
export const PATCH_MILESTONE = 'PATCH_MILESTONE';
export const PATCH_GROUP_NAME = 'PATCH_GROUP_NAME';
export const SET_MILESTONE_FOR_PREVIEW = 'SET_MILESTONE_FOR_PREVIEW';
export const CLEAR_MILESTONE_FOR_PREVIEW = 'CLEAR_MILESTONE_FOR_PREVIEW';
export const SET_NEW_MILESTONE = 'SET_NEW_MILESTONE';
export const PATCH_NEW_MILESTONE = 'PATCH_NEW_MILESTONE';
export const REMOVE_NEW_MILESTONE = 'REMOVE_NEW_MILESTONE';
export const ADD_NEW_TASK_GROUP = 'ADD_NEW_TASK_GROUP';
export const REMOVE_TASK_GROUP = 'REMOVE_TASK_GROUP';
export const TASK_GROUP_REMOVED = 'TASK_GROUP_REMOVED';
export const NEW_TASK_GROUP_ADDED = 'NEW_TASK_GROUP_ADDED';

export class SetMilestones implements Action {
  readonly type = SET_MILESTONES;
  constructor(public payload: TaskGroup[]) {
    console.log('DlMilestoneActions::SetMilestones', payload);
  }
}
export class MoveMilestone implements Action {
  readonly type = MOVE_MILESTONE;
  constructor(public payload: TaskMovedNotification) {
    console.log('DlMilestoneActions::MoveMilestone', payload);
  }
}
export class AddMilestone implements Action {
  readonly type = ADD_MILESTONE;
  constructor(public payload: QuestTask, public groupIndex: number) {
    console.log('DlMilestoneActions::AddMilestone', payload);
  }
}

export class RemoveMilestone implements Action {
  readonly type = REMOVE_MILESTONE;
  constructor(public taskIndex: number, public groupIndex: number) {
    console.log('DlMilestoneActions::RemoveMilestone');
  }
}

export class UpdateMilestoneTask implements Action {
  readonly type = UPDATE_MILESTONE_TASK;
  constructor(public payload: {taskIndex: number, groupIndex: number, task: QuestTask}) {
    console.log('DlMilestoneActions::UpdateMilestoneTask', payload);
  }
}

export class UpdateMilestoneTaskCompletion implements Action {
  readonly type = UPDATE_MILESTONE_TASK_COMPLETION;
  constructor(public payload: {taskIndex: number, groupIndex: number, value: boolean}) {
    console.log('DlMilestoneActions::UpdateMilestoneTaskCompletion', payload);
  }
}

export class UpdateTaskPosition implements Action {
  readonly type = UPDATE_TASK_POSITION;
  constructor(public payload: MoveTaskPayload, public notification: TaskMovedNotification) {
    console.log('DlMilestoneActions::UpdateTaskPosition', payload, notification);
  }
}

export class TaskPositionUpdated implements Action {
  readonly type = TASK_POSITION_UPDATED;
  constructor(public task: QuestTask, public notification: TaskMovedNotification) {
    console.log('DlMilestoneActions::TaskPositionUpdated', task, notification);
  }
}

export class DlMilestonesClearData implements Action {
  readonly type = DL_MILESTONES_CLEAR_DATA;
}

export class AddQuestLink implements Action {
  readonly type = ADD_QUEST_LINK;
  constructor(public taskIndex: number, public groupIndex: number, public taskId: number, public linkedQuestId: number) {
  }
}

export class AddMilestoneImageUrl implements Action {
  readonly type = ADD_MILESTONE_IMAGE_URL;
  constructor(public payload: {taskIndex: number, groupIndex: number, value: string}) {}
}

export class CreateDefaultTaskGroup implements Action {
  readonly type = CREATE_DEFAULT_TASK_GROUP;
  constructor(public questId: number, public groupName: string, public groupIndex: number) {
    console.log('DlMilestoneActions::CreateDefaultTaskGroup', questId, groupName, groupIndex);
  }
}

export class UpdateMilestoneGroup implements Action {
  readonly type = UPDATE_MILESTONE_GROUP;
  constructor(public payload: TaskGroup) {
    console.log('DlMilestoneActions::UpdateMilestoneGroup', payload);
  }
}

export class PatchMilestone implements Action {
  readonly type = PATCH_MILESTONE;
  constructor(public payload: PatchMilestonePayload) {}
}

export class PatchGroupName implements Action {
  readonly type = PATCH_GROUP_NAME;
  constructor(public payload: {index: number, value: string}) {}
}

export class SetMilestoneForPreview implements Action {
  readonly type = SET_MILESTONE_FOR_PREVIEW;
  constructor(public payload: TaskPreviewPayload) {}
}
export class ClearMilestoneForPreview implements Action {
  readonly type = CLEAR_MILESTONE_FOR_PREVIEW;
}
export class SetNewMilestone implements Action {
  readonly type = SET_NEW_MILESTONE;
  constructor(public payload: QuestTask, public groupIndex: number) {}
}
export class PatchNewMilestone implements Action {
  readonly type = PATCH_NEW_MILESTONE;
  constructor(public payload: QuestTask, public groupIndex: number) {}
}
export class RemoveNewMilestone implements Action {
  readonly type = REMOVE_NEW_MILESTONE;
}

export class AddNewTaskGroup implements Action {
  readonly type = ADD_NEW_TASK_GROUP;
  constructor(public questId: number, public groupName: string, public groupOwnerId: number) {
    console.log('DlMilestoneActions::AddNewTaskGroup', questId, groupName, groupOwnerId);
  }
}

export class RemoveTaskGroup implements Action {
  readonly type = REMOVE_TASK_GROUP;
  constructor(public groupIndex: number) {
    console.log('DlMilestoneActions::RemoveTaskGroup', groupIndex);
  }
}

export class TaskGroupRemoved implements Action {
  readonly type = TASK_GROUP_REMOVED;
  constructor(public groupIndex: number) {
    console.log('DlMilestoneActions::TaskGroupRemoved', groupIndex);
  }
}

export class NewTaskGroupAdded implements Action {
  readonly type = NEW_TASK_GROUP_ADDED;
  constructor(public taskGroup: TaskGroup) {
    console.log('DlMilestoneActions::NewTaskGroupAdded', taskGroup);
  }
}

export type DlMilestoneActions = SetMilestones |
                                 AddNewTaskGroup |
                                 NewTaskGroupAdded |
                                 RemoveTaskGroup |
                                 TaskGroupRemoved |
                                 MoveMilestone |
                                 AddMilestone |
                                 UpdateMilestoneTask |
                                 UpdateMilestoneTaskCompletion |
                                 UpdateTaskPosition |
                                 TaskPositionUpdated |
                                 DlMilestonesClearData |
                                 RemoveMilestone |
                                 AddQuestLink |
                                 AddMilestoneImageUrl |
                                 UpdateMilestoneGroup |
                                 PatchMilestone |
                                 PatchGroupName |
                                 SetMilestoneForPreview |
                                 ClearMilestoneForPreview |
                                 SetNewMilestone |
                                 PatchNewMilestone |
                                 RemoveNewMilestone;
