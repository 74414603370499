import { Action } from '@ngrx/store';
import * as fromUserFavorites from './user-favorites.reducer';

export const TRY_GET_USER_FAVORITES = 'TRY_GET_USER_FAVORITES';
export const GET_USER_FAVORITES = 'GET_USER_FAVORITES';
export const CLEAR_USER_FAVORITES = 'CLEAR_USER_FAVORITES';

export class TryGetUserFavorites implements Action {
  readonly type = TRY_GET_USER_FAVORITES;
}

export class GetUserFavorites implements Action {
  readonly type = GET_USER_FAVORITES;

  constructor(public payload: fromUserFavorites.State[]) {}
}

export class ClearUserFavorites implements Action {
  readonly type = CLEAR_USER_FAVORITES;
}

export type UserFavoritesActions =  TryGetUserFavorites |
                                    GetUserFavorites |
                                    ClearUserFavorites;
