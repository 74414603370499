import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { EnvironmentModel } from '../_interface/environment-model.types';
import { EnvironmentService } from '../_interface/environment-service.types';

@Injectable({
  providedIn: 'root',
})
export class StaticEnvironmentService implements EnvironmentService {

  getEnvironment(): EnvironmentModel {
    return {
      target: environment.target,
      context: environment.context
    };
  }

}
