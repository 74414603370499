import { User, UserPermission } from 'src/app/_interface/user-profile.types';
import { QuestUserInfo } from '../../../../_interface/quest.types';
import {LocalStorageService} from '../../../../_services/localstorage.service';
import * as ProfileActions from './profile.actions';
import { AccountPlan } from 'src/app/_interface/account-tier.types';

export interface State extends QuestUserInfo {
  hasGotCurrentFriends?: boolean;
  currentFriends?: User[]; //made it optional as setting values of user is not working at times.
  hasGotFriendRequests?: boolean;
  friendRequests?: User[]; //made it optional as setting values of user is not working at times.
  hasGotFriendsNoAction?: boolean;
  friendsNoAction?: User[]; //made it optional as setting values of user is not working at times.
  isMembershipManager?: boolean;
  membershipManagerPlans?: AccountPlan[];
  userPermissions?: UserPermission;
  allowCreateTemplates?: boolean;
  allowUseTemplatesPro?: boolean;
  allowUseTemplatesEnt?: boolean;
}

export const initialState: State = {
  active: false,
  country: null,
  coverPictureURL: null,
  createdOn: null,
  email: null,
  emailValidated: false,
  firstName: null,
  id: null,
  identifier: null,
  isUserBrand: null,
  lastLogin: null,
  lastName: null,
  missionStatement: null,
  name: null,
  profilePictureURL: null,
  receiveEmail: null,
  stripeEntityId: null,
  updatedOn: null,
  userName: null,
  userNonProfit: false,
  zip: null,
  absorbFees: false,
  realtimeQuestId: null,
  plan: null,
  centerX: null,
  centerY: null,
  zoomValue: null,
  accountTier: null,
  secondaryEmail: null,
  hasGotCurrentFriends: false,
  currentFriends: [],
  hasGotFriendRequests: false,
  friendRequests: [],
  hasGotFriendsNoAction: false,
  friendsNoAction: [],
  themeColor: null,
  accenColor: null,
  isMembershipManager: false,
  membershipManagerPlans: [],
  userPermissions: null,
  allowCreateTemplates: false,
  allowUseTemplatesPro: false,
  allowUseTemplatesEnt: false,
};

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateUserInfo';

export function ProfileReducer(
  state = localStorageService.getInitialState(LOCAL_STATE_KEY, initialState, true),
  action: ProfileActions.ProfileActions
) {
  switch (action.type) {
    case (ProfileActions.GET_USER_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        ...action.payload
      });
    case (ProfileActions.UPDATE_USER_INFO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        ...action.payload
      });
    case (ProfileActions.CLEAR_USER_INFO):
      localStorageService.clear(LOCAL_STATE_KEY);
      return {
        ...state,
        ...initialState
      };
      case (ProfileActions.REALTIME_QUEST_STARTED) :
        return {
          ...state,
          realtimeQuestId : (action as ProfileActions.RealtimeQuestStarted).questId,
          realtimeUserId : (action as ProfileActions.RealtimeQuestStarted).userId
        };
      case (ProfileActions.REALTIME_QUEST_REPEATED) :
        return {
          ...state,
          realtimeQuestId : (action as ProfileActions.RealtimeQuestRepeated).questId,
          realtimeUserId : (action as ProfileActions.RealtimeQuestRepeated).userId
        };
      case (ProfileActions.REALTIME_QUEST_FINISHED) :
        return {
          ...state,
          realtimeQuestId : null,
          realtimeUserId : null
        };
    case (ProfileActions.UPDATE_USER_ACCOUNT_TIER_INFO):
      return {
        ...state,
        accountTier: action.payload
      }
    case (ProfileActions.GET_FRIENDS_CURRENT):
      return {
        ...state,
        hasGotCurrentFriends: true
      }
    case (ProfileActions.SET_FRIENDS_CURRENT):
      return {
        ...state,
        currentFriends: action.payload,
        // hasGotCurrentFriends: true
      }
    case (ProfileActions.GET_FRIENDS_REQUESTS):
      return {
        ...state,
        hasGotFriendRequests: true
      }
    case (ProfileActions.SET_FRIENDS_REQUESTS):
      return {
        ...state,
        friendRequests: action.payload,
        // hasGotFriendRequests: true
      }
    case (ProfileActions.GET_FRIENDS_NO_ACTION):
      return {
        ...state,
        hasGotFriendsNoAction: true
      }
    case (ProfileActions.SET_FRIENDS_NO_ACTION):
      return {
        ...state,
        friendsNoAction: action.payload,
      }
    case (ProfileActions.UPDATE_MEMBERSHIP_MANAGER_DETAILS):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        isMembershipManager: action.isManager,
        membershipManagerPlans: action.allPlans
      });
    case ProfileActions.UPDATE_USER_PERMISSIONS:
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        userPermissions: action.payload
      });
    case (ProfileActions.UPDATE_ALLOW_CREATE_TEMPLATES):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        allowCreateTemplates: action.payload
      });
    case (ProfileActions.UPDATE_ALLOW_USE_TEMPLATES_PRO):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        allowUseTemplatesPro: action.payload
      });
    case (ProfileActions.UPDATE_ALLOW_USE_TEMPLATES_ENT):
      return localStorageService.set(LOCAL_STATE_KEY, {
        ...state,
        allowUseTemplatesEnt: action.payload
      });
    default:
      return state;
  }
}
