import { Directive, Output, EventEmitter, OnInit, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appLoadmoreTrigger]'
})
export class LoadmoreTriggerDirective implements OnInit, OnChanges {
  @Input() newContentLoaded: boolean;
  @Input() innerEl: Element;
  @Output() triggerLoadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit () {
    this.checkContentHeight();
  }

  ngOnChanges() {
    if (this.newContentLoaded) {
      this.checkContentHeight();
    }
  }

  checkContentHeight() {
    setTimeout(() => {
      const containerElem: Element = this.elementRef.nativeElement;

      if (containerElem && this.innerEl) {
        const containerContentHeight = parseInt(window.getComputedStyle(containerElem).getPropertyValue('height'), 10);
        const innerHeight = parseInt(window.getComputedStyle(this.innerEl).getPropertyValue('height'), 10);
        this.triggerLoadMore.emit(innerHeight < containerContentHeight);
      }
    });
  }

}
