import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { mergeMap, switchMap } from "rxjs/operators";
import * as fromApp from '../../../../_store/app.reducers';
import { AccountService } from "../account.service";
import { GET_USER_ACCOUNT_COLORS, SetAccountColors, USER_ACCOUNT_COLORS } from "./account.actions";
import { AccountColor } from "./account.reducer";

@Injectable()
export class AccountEffects {

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private accountService: AccountService
  ) {}


  
  accountGetColor = createEffect(() => this.actions$
    .pipe(
      ofType(GET_USER_ACCOUNT_COLORS),
      // map((action: GetAccountColors) => {
      //   return action.payload;
      // }),
      switchMap((accountDet: { payload: {userId: number} }) => {
        return this.accountService.getCustomColor(accountDet.payload.userId).pipe(
          mergeMap((resData: AccountColor) => {
            //had to dispatch as the return was not working
            this.store.dispatch(new SetAccountColors(resData));
            if (resData.accentColor && resData.themeColor && 
              resData.accentColor !== '' && resData.themeColor !== '') {
              this.accountService.changeRootColor(resData, accountDet.payload.userId);
            }

            return [
              {
                type: USER_ACCOUNT_COLORS,
                payload: resData
              }
            ];
          })
        )
      })
    ));

}