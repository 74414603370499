import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NotificationType } from 'src/app/_interface/notification.types';
import * as fromApp from './../../../../../_store/app.reducers';
import { Subject, map, take, takeUntil, tap } from 'rxjs';
import * as fromProfile from './../../../../pages/profile/store/profile.reducer';
import { NotificationsService } from '../../notifications.service';
import { GetNotifications, SetNotifications, SetNotificationsInfo } from '../../store/notifications.actions';
import { Router } from '@angular/router';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';
import { FriendsService } from 'src/app/components/pages/friends/friends.service';
import { NotifierService } from 'angular-notifier';
import { GetFriendsCurrent, GetFriendsRequests } from 'src/app/components/pages/profile/store/profile.actions';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, OnDestroy {

  notifications: NotificationType[] = [];

  @Output('toggleNotificationsMenu') toggleNotificationsMenu: EventEmitter<boolean> = new EventEmitter(false);
  
  @Output('markedNotifRead') markedNotifRead: EventEmitter<boolean> = new EventEmitter(false);

  private destroyed$: Subject<void> = new Subject();

  loadStep = 10;

  pagination = {
    from: 0,
    to: this.loadStep
  };

  usersQuests: { [key: number]: ExploreCardListType } = {};

  isLoaded: boolean = false;

  reachedEnd: boolean = false;

  btnIsLoading: boolean = false;

  constructor(private store: Store<fromApp.AppState>,
    private notificationsService: NotificationsService,
    private router: Router,
    private friendsService: FriendsService,
    private notifier: NotifierService) {}

  ngOnInit() {
    this.store.select('notifications').subscribe(notifState => {
      this.notifications = notifState.notifications;
      this.usersQuests = notifState.userQuests;
      this.isLoaded = notifState.hasCalledNotificationApi;
      this.reachedEnd = notifState.allLoaded;
      this.btnIsLoading = notifState.loadingNotifications;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  goToThatUser(userId, item) {
    if (item.user && item.user.plan && item.user.plan.isPrivate) return;
    
    this.notificationsService.readNotification(item).subscribe((_val) => {
      this.router.navigate(['/profile/' + userId]);
      this.toggleNotificationsMenu.emit(true);
    });
  }

  goToThatPage(item: NotificationType) {
    this.notificationsService.readNotification(item).subscribe((_val) => {
      if (!item.isRead) { 
        item.isRead = true;
        this.markedNotifRead.emit(true);
      }
      // this.notificationsCount -= 1;
      const destinationURL = (route) => 
        [ route, 
          item.quest.seoSlugs.questId, 
          (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId) 
            ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
            : item.userId)
        ]
      switch (item.notificationType) {
        case 'COMMENT_REPLIED': // reply by a user on a comment
        case 'POST_COMMENT': // comment on the post by user
        case 'POST_CHEER': //when the post is cheered
        case 'COMMENT_CHEER': //when the comment is cheered
        case 'REPLY_CHEER': //when the reply is cheered
        case 'COMMENT_MENTION': //when user is mentioned in comment
        case 'POST_MENTION': // when user is mentioned in the post
        case 'POST_CHEERS': // when post is liked
          this.router.navigate(destinationURL('/quest-detail'), {
            queryParams: {
              open: 'feed',
              commentId: item.commnetId,
              postId: item.postId,
              replyId: item.repliedId
            }
          });
          break;
        
        case 'QUEST_SAVED':
        case 'QUEST_STARTED':
        case 'QUEST_ACHIEVED':
        case 'QUEST_BACKED':
        case 'EVENT_STARTED':
        case 'FUNDRAISER_STARTED':
        case 'TASK_ACHIEVED':
        case 'FOLLOWED':
          this.router.navigate(['/quest-detail', item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
            ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
            : item.userId)]);
          break;
        
        case 'PHOTO_VIDEO_ADDED':
          this.router.navigate(destinationURL('/quest-detail'), {
            queryParams: { gallery: true }
          });
          break;

        case 'PROFILE_BACKED':
          this.router.navigate(['/profile']);
          break;
        
        case 'CONNECTION_REQUEST_APPROVED':
        case 'CONNECTION_REQUEST_ACCEPTED':
        case 'FRIEND_REQUEST_ACCEPTED': // when connection request accepted
        case 'FRIEND_REQUEST': // when connection request is received
        case 'CONNECTION_REQUEST':  // when connection request is received. same as FRIEND_REQUEST
          this.router.navigate(['/profile', item.userId]);
          break;

        default:
          this.router.navigate(['/quest-detail', item.quest.seoSlugs.questId, (this.usersQuests.hasOwnProperty(item.quest.seoSlugs.questId)
            ? this.usersQuests[item.quest.seoSlugs.questId].seoSlugs.userId
            : item.userId)]);
          break;

        // case 'COMMENT':
        //   // pass the comment id to be focussed
        //   this.router.navigate(destinationURL('/quest-detail'), {
        //     queryParams: { 
        //       focusCommentId: item.comment && item.comment.id, 
        //       postId: item.comment && item.comment.id
        //     }
        //   });
        //   break;

        // case 'FRIEND_REQUEST':
        //   this.router.navigate(['/connections/requests']);
        //   break;

        // //Newsfeed
        // case 'LOGGED_ACTIVITY':
        //   // pass the post id to be focussed
        //   this.router.navigate(destinationURL('/quest-detail'), {
        //     queryParams: { focusPostId: item.comment && item.comment.id }
        //   });
        //   break;

        // case 'COMMENT_LIKED':
        //   this.router.navigate(destinationURL('/quest-detail'), {
        //     queryParams: {
        //       focusCommentId: item.comment && item.comment.id, 
        //       postId: item.comment && item.comment.id
        //     }
        //   });
        //   break;

        // case 'COMMENT_MENTION':
        //   this.router.navigate(destinationURL('/quest-detail'), {
        //     queryParams: {
        //       focusCommentId: item.comment && item.comment.id, 
        //       postId: item.comment && item.comment.id
        //     }
        //   });
        //   break;
      }
      this.toggleNotificationsMenu.emit(true);
    });
  }

  loadMore() {
    if (!this.btnIsLoading) {
      this.btnIsLoading = true;
      this.pagination.from += this.loadStep;
      // this.pagination.to += this.loadStep;
      this.store.select('userInfo').pipe(take(1)).subscribe(userInfoState => {
        this.store.dispatch(new GetNotifications(userInfoState.id, this.pagination.from, this.loadStep));
      })
    }
  }

  acceptFriendRequest(item: NotificationType) {
    // this.isLoading = true;

    this.friendsService.acceptFriendRequest(item.userId).subscribe((res) => {
      // this.isLoading = false;
      // this.friendInfo.status = 'approved';
      // this.friendInfo['acceptRequest'] = false;
      // this.cdr.detectChanges();
      item.isRead = true;
      item.requestStatus = 1;
      // item.notificationType = 'CONNECTION_REQUEST_APPROVED';

      this.notifier.notify('success', 'Connection request accepted');

      this.store.dispatch(new GetFriendsCurrent());
      this.store.dispatch(new GetFriendsRequests());
    }, () => {
      this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
    });
  }

  cancelFriendRequest(item: NotificationType) {
    // this.isLoading = true;

    this.friendsService.cancelFriendRequest(item.userId).subscribe((res) => {
      // this.isLoading = false;
      // this.removeItem.emit({
      //   value: this.friendInfo.id
      // });
      // this.cdr.detectChanges();
      item.isRead = true;
      item.requestStatus = 2;
      // item.notificationType = 'CONNECTION_REQUEST_APPROVED';

      this.store.dispatch(new GetFriendsRequests());
      }, () => {
      this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
    });
  }

  notificationTrackBy(id, notification) {
    return notification.id;
  }
}
