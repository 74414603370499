import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';
import {HTTPStatus} from 'src/app/interceptors/loader.interceptor';
import {Observable} from 'rxjs';
import { AccountPlan } from 'src/app/_interface/account-tier.types';

export interface NewAccountInfo {
  firstName: string;
  lastName: string;
  email: string;
  company: {
    name: string;
    orgType: string;
    website?: string;
    phone?: string;
    address: {
      country: string;
      lineOne: string;
      lineTwo?: string;
      city?: string;
      state?: string;
      zip: string;
    };
  };
  recaptcha: boolean;
  withPin: boolean;
  receiveEmail: boolean;

  country?: string;
  zip?: number;
  password1?: string;
  password2?: string;
  passwordGroup?: {
    password1: string,
    password2: string
  };
  type?: string;
}

interface CheckEmailRes {
  "deleted": boolean;
  "validated": boolean;
  "provider": 'DiemLife' | 'Click';
  "exists": boolean;
  "active": boolean;
  "userId": number;
  plan: AccountPlan;
}

@Injectable({
  providedIn: 'root',
})
export class SignUpService {

  constructor(
    private http: HttpClient,
    private httpStatus: HTTPStatus,
    private reaquestHeadersService: ReaquestHeadersService
  ) { }

  createAccount (payload: NewAccountInfo) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/register',
      payload,
      { headers: headers }
    );
  }
  checkEmail (email: string): Observable<CheckEmailRes> {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post<CheckEmailRes>(
      environment.target + environment.context + '/check-email',
      {email: email},
      { headers: headers }
    );
  }
  requestPin (email: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/accounts/pin/generate',
      {email: email},
      { headers: headers }
    );
  }
  validatePin (email: string, code: string): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/accounts/pin/verify',
      { email: email, code: code },
      { headers: headers }
    );
  }

  createBrand (payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/brand',
      payload,
      { headers: headers }
    );
  }

  updateAddressForBrand(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.post(
      environment.target + environment.context + '/brand/update-address',
      payload,
      { headers: headers }
    );
  }

  sendVerificationEmail(userId: number, planId: number = 0) {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.get(
      environment.target + environment.context + `/sendVerificationEmail/${userId}/${planId || 0}`,
      { headers: headers }
    );
  }

  verifyUserUsingToken(token: string, getResponse: boolean = false): Observable<{"token": string}> {
    const headers = this.reaquestHeadersService.getHeaders();
    this.httpStatus.setHttpStatus(true);
    return this.http.get<{"token": string}>(
      environment.target + environment.context + `/accounts/verify/${token}/${getResponse}`,
      { headers: headers }
    );
  }
}
