import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ReaquestHeadersService } from '../../../../_services/requestHeaders.service';
import { HTTPStatus } from '../../../../interceptors/loader.interceptor';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { EMPTY, of } from 'rxjs';
import { AccountService } from 'src/app/components/pages/account/account.service';
import * as fromApp from '../../../../_store/app.reducers';
import { SetAccountColors } from '../../../pages/account/store/account.actions';
import * as ProfileActions from './../../../pages/profile/store/profile.actions';
import * as RecentTransactionsActions from './../../../pages/profile/store/recent-transactions.actions';
import * as UserFavoritesActions from './../../../pages/profile/store/user-favorites.actions';
import * as NotificationsActions from '../../notifications/store/notifications.actions';
import * as AuthActions from './auth.actions';
import { PasswordRecoveryData } from './auth.actions';
import { CommonService } from 'src/app/_services/common.service';

@Injectable()
export class AuthEffects {

  constructor(
    private httpStatus: HTTPStatus,
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private modalServices: NgbModal,
    private reaquestHeadersService: ReaquestHeadersService,
    private notifier: NotifierService,
    private store: Store<fromApp.AppState>,
    private accountService: AccountService,
    private commonService: CommonService
  ) {}

  closeAuthModal() {
    this.modalServices.dismissAll();
  }

  
  authSignin = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN),
      map((action: AuthActions.TrySignin) => {
        return action.payload;
      }),
      switchMap((authData: { email: string, password: string }) => {
        this.httpStatus.setHttpStatus(true);
        const formData = new FormData();
        formData.append('email', authData.email);
        formData.append('password', authData.password);
        this.httpStatus.setHttpStatus(true);
        return this.http.post(
          environment.authApi + environment.authContext + '/login/token',
          formData,
          {
            withCredentials: true
          }
        ).pipe(
          mergeMap((data: { token: string, expires: number }) => {
            this.closeAuthModal();

            setTimeout(() => {
              this.closeAuthModal();
            }, 500);
            sessionStorage.removeItem('NEW_ACCOUNT_INFO');
            const now = new Date();
            
            return [
              {
                type: AuthActions.SET_TOKEN,
                payload: { token: data.token, tokenUpdateTime: now.getTime() }
              },
              {
                type: AuthActions.SET_EXPIRES,
                payload: data.expires
              },
              {
                type: AuthActions.SIGNIN
              },
              {
                type: ProfileActions.TRY_GET_USER_INFO
              },
              {
                type: AuthActions.AUTH_SET_LOADING_STATE,
                payload: false
              }
            ];
          }),
          catchError((err) => {
            let message: string;
            switch (err.status) {
              case 401:
                message = `Please check your email or password`;
                break;
              default:
                // console.log(JSON.stringify(err));
                message = 'There was an issue completing your request. Please try again.';
            }
            this.notifier.notify('error', message);
            return of(new AuthActions.AuthSetLoadingState(false));
          })
        );
      })
    ));
  
  authSwitchCompany = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.LOGIN_PLATFORM_TO_COMPANY),
      map((action: AuthActions.LoginPlatformToCompany) => {
        return action.payload;
      }),
      switchMap((authData: { userId: number, companyId: number, currentCompanyId: number }) => {
        this.store.dispatch(new ProfileActions.ClearUserInfo());
        
        this.httpStatus.setHttpStatus(true);
        return this.http.get(
          environment.authApi + environment.authContext + `/token/user/${authData.userId}/${authData.companyId}`,
          {
            withCredentials: true
          }
        ).pipe(
          mergeMap((data: { token: string, expires: number }) => {
            this.closeAuthModal();

            setTimeout(() => {
              this.closeAuthModal();
            }, 500);
            const now = new Date();
            
            return [
              {
                type: AuthActions.SET_TOKEN,
                payload: { token: data.token, tokenUpdateTime: now.getTime() }
              },
              {
                type: AuthActions.SET_EXPIRES,
                payload: data.expires
              },
              {
                type: ProfileActions.TRY_GET_USER_INFO
              },
              {
                type: AuthActions.AUTH_SET_LOADING_STATE,
                payload: false
              },
              {
                type: AuthActions.PLATFORM_COMPANY_CHANGED,
                payload: {oldId: authData.currentCompanyId, newId: authData.companyId}
              }
            ];
          }),
          catchError((err) => {
            let message: string = 'There was an issue completing your request. Please try again.';
            this.notifier.notify('error', message);
            return of(new AuthActions.AuthSetLoadingState(false));
          })
        );
      })
    ));

  authResetCompany = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.RESET_LOGIN_TO_PLATFORM),
      switchMap(() => {
        const origAuthInfo = JSON.parse(localStorage.getItem('stateAuthOrig'));

        localStorage.setItem('stateAuth', localStorage.getItem('stateAuthOrig'));
        localStorage.setItem('stateUserInfo', localStorage.getItem('stateUserInfoOrig'));

        localStorage.removeItem('stateAuthOrig');
        localStorage.removeItem('stateUserInfoOrig');
        
        return [
          {
            type: AuthActions.SET_TOKEN,
            payload: {...origAuthInfo}
          },
          {
            type: AuthActions.SET_EXPIRES,
            payload: origAuthInfo.expires
          },
          {
            type: ProfileActions.TRY_GET_USER_INFO
          },
          {
            type: AuthActions.PLATFORM_COMPANY_CHANGED,
            payload: {oldId: null, newId: null}
          }
        ];
      })
    ));

  authClickSignin = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN_CLICK),
      map((action: AuthActions.TryClickSignIn) => {
        return action.payload;
      }),
      switchMap((authData: { email: string, password: string }) => {
        this.httpStatus.setHttpStatus(true);
        return this.http.post(
          environment.authApi + environment.authContext + '/login/click',
          {
            email: authData.email,
            password: authData.password
          },
          {
            withCredentials: true
          }
        ).pipe(
          mergeMap((data: { token: string, expires: number }) => {
            this.closeAuthModal();

            setTimeout(() => {
              this.closeAuthModal();
            }, 500);
            sessionStorage.removeItem('NEW_ACCOUNT_INFO');
            const now = new Date();
            
            return [
              {
                type: AuthActions.SET_TOKEN,
                payload: { token: data.token, tokenUpdateTime: now.getTime() }
              },
              {
                type: AuthActions.SET_EXPIRES,
                payload: data.expires
              },
              {
                type: AuthActions.SIGNIN
              },
              {
                type: ProfileActions.TRY_GET_USER_INFO
              },
              {
                type: AuthActions.AUTH_SET_LOADING_STATE,
                payload: false
              }
            ];
          }),
          catchError((err) => {
            let message: string;
            switch (err.status) {
              case 401:
                message = `Please check your email or password`;
                break;
              default:
                // console.log(JSON.stringify(err));
                message = 'There was an issue completing your request. Please try again.';
            }
            this.notifier.notify('error', message);
            return of(new AuthActions.AuthSetLoadingState(false));
          })
        );
      })
    ));
  
    authTryLogout = createEffect(() => this.actions$
      .pipe(
        ofType(AuthActions.TRY_LOGOUT),
        switchMap(() => {
          const headers = this.reaquestHeadersService.getHeaders();
          setTimeout(() => {
            this.store.dispatch(new SetAccountColors({accentColor: '', themeColor: ''}));
            this.accountService.resetAllRootColors();
          });
          this.httpStatus.setHttpStatus(true);
          return this.http.post(
            environment.authApi + environment.authContext + '/logout/session',
            null,
            {
              headers: headers,
              withCredentials: true
            }
          );
        }),
        mergeMap(() => {
          // clear all the local storage
          const toCheckFor = ['billing-info', 'tickets-order', 'participants-info', 'tickets-discount', 'order-number'];
          Object.keys(localStorage).forEach(_key => {
            toCheckFor.forEach(_toCheckStr => {
              if (_key.match(_toCheckStr) && _key.match(_toCheckStr).index && _key.match(_toCheckStr).index > -1) {
                localStorage.removeItem(_key);
              }
            })
          });

          setTimeout(() => {
            this.store.dispatch(new SetAccountColors({accentColor: '', themeColor: ''}));
            this.accountService.resetAllRootColors();
          });

          setTimeout(() => {
            this.store.dispatch(new SetAccountColors({accentColor: '', themeColor: ''}));
            this.accountService.resetAllRootColors();
          }, 2000);

          return [
            {
              type: AuthActions.LOGOUT
            },
            {
              type: ProfileActions.CLEAR_USER_INFO
            },
            {
              type: UserFavoritesActions.CLEAR_USER_FAVORITES
            },
            {
              type: RecentTransactionsActions.CLEAR_RECENT_TRANSACTIONS
            },
            {
              type: NotificationsActions.CLEAR_NOTIFICATIONS
            }
          ];
        })
      ));

  
  authTryRefreshToken = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.TRY_REFRESH_TOKEN),
      switchMap(() => {
        this.httpStatus.setHttpStatus(true);
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.authApi + environment.authContext + '/refresh/token',
          {
            headers: headers,
            withCredentials: true
          }
        );
      }),
      mergeMap((data: { token: string, expires: number }) => {
        const now = new Date();
        return [
          {
            type: AuthActions.REFRESH_TOKEN,
            payload: { token: data.token, tokenUpdateTime: now.getTime() }
          },
          {
            type: AuthActions.SET_EXPIRES,
            payload: data.expires
          },
          {
            type: ProfileActions.TRY_GET_USER_INFO
          }
        ];
      })
    ));

  
  authLogout = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.LOGOUT),
      tap(() => {
        this.router.navigate(['/']);
      })
    ), {dispatch: false});

  
  requestPassRecovery = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.TRY_RECOVERY_PASS),
    map((action: AuthActions.TryRecoveryPass) => {
      return action.payload;
    }),
    switchMap((recoveryData: { email: string, planId: number }) => {
      this.httpStatus.setHttpStatus(true);
      return this.http.post(environment.target + environment.context + `/forgotpassword/${recoveryData.planId || 0}`, recoveryData).pipe(
        map((res: {msg: string}) => {
          this.notifier.notify('info', res.msg);
          return of(EMPTY);
        }),
        catchError((error) => {
          // tslint:disable-next-line:max-line-length
          const message = 'There was an issue completing your request. Please try again.';
          this.notifier.notify('error', message);
          return of(EMPTY);
        })
      );
    }),
    mergeMap(() => {
      return of(new AuthActions.AuthSetLoadingState(false));
    })
  ));

  
  recoveryPass = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.RECOVERY_PASS),
    map((action: AuthActions.RecoveryPass) => {
      return action.payload;
    }),
    switchMap((recoveryData: PasswordRecoveryData) => {
      this.httpStatus.setHttpStatus(true);
      return this.http.post(environment.target + environment.context + `/accounts/password/reset/${recoveryData.resetFor || 0}`, recoveryData, {
        withCredentials: true
      }).pipe(
        map((res) => {
          if (res && res['email'] && res['email'] !== '') {
            recoveryData.firstTimeSet ? this.notifier.notify('info', 'Password set successfully.') : this.notifier.notify('info', 'Password reset successful.');

            this.store.dispatch(new AuthActions.TrySignin({
              email: res['email'],
              password: recoveryData.password
            }));
          } else {
            this.notifier.notify('info', 'Password reset. Please log in.');

            this.router.navigate(['/landing']);
          }
        }),
        catchError((error) => {
          this.notifier.notify('error', 'Issue resetting password.');

          return of(error);
        })
      );
    })
  ), { dispatch: false });

  
  socialSignin = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.TRY_SIGNIN_SOCIAL),
      map((action: AuthActions.TrySigninSocial) => {
        return action.payload;
      }),
      switchMap((authData: { id: string, 
        name: string,
        email: string
        firstName: string
        lastName: string
        imageUrl: string,
        provider: string
      }) => {
        this.httpStatus.setHttpStatus(true);
        const _url = authData.provider === 'FACEBOOK' ? '/login/facebook' : '/loginnew/google';

        return this.http.post(
          environment.authApi + environment.authContext + _url,
          {
            "id": authData.id,
            "provider": authData.provider,
            "name": authData.name,
            "email": authData.email,
            "firstName": authData.firstName,
            "lastName": authData.lastName,
            "imageUrl": authData.imageUrl,
            "locale": "EN" 
          },
          {
            withCredentials: true
          }
        ).pipe(
          mergeMap((data: { token: string, expires: number }) => {
            if(!data.token || data.token === null) {
              return [{
                type: AuthActions.ASK_FOR_EMAIL_AND_LOGIN_SOCIAL,
                payload: true
              }]
            }

            this.closeAuthModal();

            setTimeout(() => {
              this.closeAuthModal();
            }, 500);
            sessionStorage.removeItem('NEW_ACCOUNT_INFO');
            const now = new Date();
            return [
              {
                type: AuthActions.SET_TOKEN,
                payload: { token: data.token, tokenUpdateTime: now.getTime() }
              },
              {
                type: AuthActions.SET_EXPIRES,
                payload: data.expires
              },
              {
                type: AuthActions.SIGNIN
              },
              {
                type: ProfileActions.TRY_GET_USER_INFO
              },
              {
                type: AuthActions.AUTH_SET_LOADING_STATE,
                payload: false
              }
            ];
          }),
          catchError((err) => {
            let message: string;
            switch (err.status) {
              case 401:
                message = `Please check your email or password`;
                break;
              default:
                // console.log(JSON.stringify(err));
                message = 'There was an issue completing your request. Please try again.';
            }
            this.notifier.notify('error', message);
            return of(new AuthActions.AuthSetLoadingState(false));
          })
        );
      })
    ));

  updateLastLogin = createEffect(() => this.actions$
    .pipe(
      ofType(AuthActions.UPDATE_LAST_LOGIN),
      switchMap((data: {emailId: string}) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.authApi + environment.context + `/signup/updatelastupdate/${data.emailId}`,
          {
            headers: headers
          }
        );
      })
    ), {dispatch: false});
}
