import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs';
import { AppState } from 'src/app/_store/app.reducers';
import { MEMBER_STATUS_PRIORITY } from 'src/app/app.constants';
import { GetNotifications } from 'src/app/components/main/notifications/store/notifications.actions';
import { GetFriendsCurrent, GetFriendsRequests } from '../../profile/store/profile.actions';
import { FriendsService } from '../friends.service';

@Component({
  selector: 'app-friends-item',
  templateUrl: './friends-item.component.html',
  styleUrls: ['./friends-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FriendsItemComponent implements OnInit {
  @Output() closeDialog: any = new EventEmitter<void>();
  @Output("removeItem") removeItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() manualClick: any = new EventEmitter<void>();
  @Input() isManualClickEvent: boolean;
  @Input() friendInfo: any; //fromProfileFriends.State;
  @Input() point: string;
  @Input() showBacking: boolean = false;
  isLoading = false;
  memberStatuses: {[key: string]: boolean} = {};

  @Input('isTemplate') isTemplate: boolean = false;

  constructor(
    private friendsService: FriendsService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private notifier: NotifierService
  ) {
    Object.keys(MEMBER_STATUS_PRIORITY).forEach(key => {
      this.memberStatuses[key] = false;
    });
  }

  ngOnInit() {    
    if (this.point === 'member' && this.friendInfo.memberStatus && this.friendInfo.memberStatus.length > 0) {
      const statusByPriority = this.friendInfo.memberStatus.sort((a, b) => {
        return MEMBER_STATUS_PRIORITY[a] - MEMBER_STATUS_PRIORITY[b];
      });
      statusByPriority.forEach((item) => {
        this.memberStatuses[item] = true;
      });
    }
  }

  acceptFriendRequest() {
    this.isLoading = true;

    this.friendsService.acceptFriendRequest(this.friendInfo.id).subscribe((res) => {
      this.friendInfo.status = 'approved';
      this.friendInfo['acceptRequest'] = false;
      // this.removeItem.emit();

      this.notifier.notify('success', 'Connection request accepted');

      this.store.dispatch(new GetFriendsCurrent());
      this.store.dispatch(new GetFriendsRequests());
      this.store
        .pipe(
          select('userInfo'),
          take(1)
        ).subscribe(userSt => {
          this.store.dispatch(new GetNotifications(userSt.id, 0, 10));
        })

      this.isLoading = false;
      this.cdr.detectChanges();
    }, () => {
      this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
    });
  }

  cancelFriendRequest() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.friendsService.cancelFriendRequest(this.friendInfo.id).subscribe((res) => {
      this.isLoading = false;
      this.removeItem.emit({
        value: this.friendInfo.id
      });
      this.store
        .pipe(
          select('userInfo'),
          take(1)
        ).subscribe(userSt => {
          this.store.dispatch(new GetNotifications(userSt.id, 0, 10));
        })
      this.cdr.detectChanges();

      this.store.dispatch(new GetFriendsRequests());
      }, () => {
      this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
    });
  }

  onCloseDialog() {
    this.closeDialog.emit();
  }

  onManualClick() {
    this.manualClick.emit();
    if (this.friendInfo.userId > 0 && !this.friendInfo.anonymous) {
      this.onCloseDialog();
    }
  }
}
