import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/_services/common.service';
import { META_IMAGE_DIEMLIFE } from 'src/app/app.config';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit, OnDestroy {

  constructor(private meta: Meta,
    private titleService: Title,
    private commonService: CommonService) { }

  ngOnInit() {
    this.setMetaTags();
  }

  private setMetaTags() {
    this.titleService.setTitle('Connections | DIEMlife');
    this.meta.updateTag({property: 'og:title', content: 'Connections | DIEMlife'});
    this.meta.updateTag({property: 'og:image', content: META_IMAGE_DIEMLIFE});
    this.meta.updateTag({property: 'og:description', content: 'Manage and view your connections in DIEMlife.'});
  }

  ngOnDestroy(): void {
    this.commonService.setMetaTagsToDIEMlife();
  }
}
