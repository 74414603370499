import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { KeysPipe } from 'src/app/_pipes/keys.pipe';
import { ReferenceDataService } from 'src/app/_services/reference-data.service';
import * as Constants from '../../../../../app.constants';

@Component({
  selector: 'app-brand-advance-details',
  templateUrl: './advance-details.component.html',
  styleUrls: ['./advance-details.component.scss']
})
export class AdvanceDetailsComponent implements OnInit {
  @Output("valueChange") valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() formSubmitted: boolean = false;
  businessForm: UntypedFormGroup;
  messages = Constants.VALIDATION_MESSAGES;
  countries: {key: string, value: any}[];
  eventsSubject: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private referenceDataService: ReferenceDataService,
    private keysPipe: KeysPipe
  ) {
    this.businessForm = this.fb.group({
      'businessAddLine1': ['', [Validators.required]],
      'businessAddLine2': ['', []],
      'city': ['', [Validators.required]],
      'state': ['', [Validators.required]],
      'country': [null, [Validators.required]],
      'zipcode': ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/)]],
      'phone': ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.referenceDataService.getSupportedCountries().subscribe((res: {[key: string]: string}) => {
      this.countries = this.keysPipe.transform(res);
      this.eventsSubject.next();
    });
    
    this.businessForm.valueChanges.subscribe(value => {
      this.valueChange.emit({
        type: 'advanced',
        isFormValid: this.businessForm.valid,
        formValues: this.businessForm.value
      })
    });
  }

  markAsTouched() {
    this.businessForm.get('country').markAsTouched();
    this.closedDropdown();
  }

  buildOption(key: string, name: string) {
    const country = key.toLowerCase();
    return `
      <i class='flag flag-${country}'></i>
      <span>${name}</span>
    `;
  }

  clearCountryField(controlName: string) {
    this.businessForm.get(controlName).patchValue('');
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }

}
