import { Directive, ElementRef, OnInit, HostListener, OnDestroy, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Subject, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, delay } from 'rxjs/operators';

declare var $: any;

@Directive({
  selector: '[appSmartEdit]'
})
export class SmartEditDirective implements OnInit, OnChanges, OnDestroy {
  @Input() smartEditManualSwitch: boolean;
  @Input() smartEditMode: boolean;
  @Input() debounce = 500;
  @Output() valueChanged: EventEmitter<string> = new EventEmitter();
  onDocumentClickBound: any;
  instanse: any;
  onInputJqueryEvent: any;
  newValueEvent = new Subject<string>();
  subscription: Subscription;
  latestValue: string;

  private config = {
    allowHTML: false,
    allowImg: false,
    singleLine: true
  };

  constructor(private elRef: ElementRef) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (this.instanse && this.instanse.hasOwnProperty('destroy')) {
      this.instanse.destroy();
    }
    if (this.onInputJqueryEvent) {
      this.onInputJqueryEvent.off();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnChanges() {
    if (this.smartEditManualSwitch && this.smartEditMode && !this.instanse) {
      this.initPlugin();
    } else if (this.instanse) {
      this.valueChanged.emit($(this.elRef.nativeElement).text());
      setTimeout(() => {
        this.destroyPlugin();
      });
    }
  }
  @HostListener('click') click() {
    if (!this.onDocumentClickBound && !this.smartEditManualSwitch) {
      this.onDocumentClickBound = this.onDocClickEvent.bind(this);
      document.addEventListener('click', this.onDocumentClickBound, false);
    }
  }
  onDocClickEvent(event) {
    if (this.elRef.nativeElement.contains(event.target)) {
      this.initPlugin();
    } else {
      if (this.instanse) {
        this.destroyPlugin();
      }
      document.removeEventListener('click', this.onDocumentClickBound, false);
      this.onDocumentClickBound = null;
    }
  }
  initPlugin() {
    const elJquery = $(this.elRef.nativeElement);
    this.instanse = elJquery.toTextarea(this.config);

    if (this.onInputJqueryEvent) {
      this.onInputJqueryEvent.off();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.newValueEvent.pipe(
      debounceTime(this.debounce / 2),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(
        delay(this.debounce / 2)
      ))
    ).subscribe((newValue) => {
      this.valueChanged.emit(newValue);
    });
    this.onInputJqueryEvent = elJquery.on('input', () => {
      this.latestValue = elJquery.text();
      this.newValueEvent.next(this.latestValue);
    });
  }
  destroyPlugin() {
    this.instanse.toTextarea('destroy');
    this.instanse = null;
    if (this.onInputJqueryEvent) {
      this.onInputJqueryEvent.off();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
