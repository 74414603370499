import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'udpCurrencyMask'
})
export class UdpCurrencyMaskV2Pipe implements PipeTransform {
    amount: any;

    transform(value: any, args?: any): any {

        let amount = String(value);

        let integers = '';
        if (typeof amount !== 'undefined') {
            integers = this.formatNumber(amount.replace(/\D+/g, ''));
        }
        amount = integers;

        return '$ ' + amount;
    }

    formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    formatString(s) {
      return +((s || '').toString().replace('$', '').split(',').join(''));
    }
}
